/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-relatedlinks a {
  text-decoration: none;
  color: inherit;
}
.widget-relatedlinks ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 30px;
}
.widget-relatedlinks__item + .widget-relatedlinks__item {
  margin-top: 20px;
}
.widget-relatedlinks__item {
  font-size: 14px;
  line-height: 160%;
}
.widget-relatedlinks__item > a {
  display: flex;
  position: relative;
}
.widget-relatedlinks__item a > span {
  display: block;
}
.widget-relatedlinks__item__image {
  width: 40%;
  margin-right: 20px;
  align-self: center;
}
.widget-relatedlinks__item__image img {
  width: 100%;
  vertical-align: bottom;
  height: auto;
}
.widget-relatedlinks__item__text {
  flex: 1 1 100%;
}
.widget-relatedlinks__item__title {
  font-size: 17px;
  display: block;
  padding-bottom: 10px;
}
a:hover .widget-relatedlinks__item__title {
  text-shadow: 0 0 1px var(--color-black);
}
.widget-relatedlinks__item__description {
  color: var(--color-dark-grey);
}