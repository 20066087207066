/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
wh-imgedit {
  --wh-imgedit-height: 200px;
  --wh-imgedit-width: 150px;
}
wh-imgedit::part(button deletebutton) {
  background-color: var(--theme-color-cta);
}

.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
  -webkit-animation: spinner-anim 2s infinite linear;
  animation: spinner-anim 2s infinite linear;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}