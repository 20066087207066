.likebutton {
  align-items: center;
  border: 1px solid var(--color-grey-lines);
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  gap: 10px;
  padding: 4px 9px !important;
  transition: border 0.2s, padding 0.2s;
}
.likebutton::before {
  content: attr(data-icon);
}
.likebutton--liked {
  border-color: var(--theme-color-cta);
  border-width: 2px;
  padding: 3px 8px !important;
}
.likebutton:hover {
  border-color: var(--color-black);
}
.likebutton--liked:hover {
  border-color: var(--theme-color-cta-hover);
}