/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  flex: none;
  padding: 0 10px;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime__sep {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.datetime__part {
  background: transparent;
  border: 0;
  min-width: 29px;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  border: 0 none !important;
  background-color: transparent !important;
  padding: 0 !important;
  max-height: 41px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__year {
  min-width: 45px;
  width: calc(4ch + 8px);
  text-align: left;
}
.datetime__year::-webkit-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year::-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-ms-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__msec {
  min-width: 36px;
  width: calc(3ch + 8px);
}
.datetime__controls {
  margin-left: 5px;
  color: inherit;
}
.datetime__controls > span {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.datetime__togglepicker::before {
  content: "\e953";
}
.datetime__reset::before {
  content: "\e92e";
}
.datetime__picker {
  position: fixed;
  border: 2px solid #1e2328;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 10px 7px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.datetime__picker__previous, .datetime__picker__next {
  display: none;
}
.datetime__picker select, .datetime__picker input {
  height: 24px;
  font-size: inherit;
}
.datetime__picker input {
  padding-left: 10px;
}
.datetime__picker__weekday {
  padding: 5px 5px 3px;
}
.datetime__picker__day {
  cursor: pointer;
  outline: 0 none;
  padding: 0 5px;
}
.datetime__picker__day span {
  border-radius: 2px;
  display: block;
  padding: 5px 5px 5px 5px;
  text-align: right;
  border: 1px solid #fff;
  transition: border-color 0.2s;
}
.datetime__picker__day--selected > span {
  background-color: #1e2328;
  color: #fff;
}
.datetime__picker__day:hover > span, .datetime__picker__day:focus > span {
  border-color: grey;
}
.datetime__picker__header {
  background-color: #1e2328;
  padding: 3px;
  display: flex;
  justify-content: space-between;
}