.addeventatc
{
  cursor: pointer;
  position: relative;
}
.addeventatc_dropdown
{
  cursor: pointer;
  width:200px;
  position:absolute;
  z-index:9999 !important;
  padding:6px 0px 0px 0px;
  background:#fff;
  text-align:left;
  display:none;
  margin-top:4px;
  margin-left:-1px;
  border: 1px solid #dce1e6;
}
.addeventatc_dropdown   span
{
  display:block;
  line-height:110%;
  background:#fff;
  text-decoration:none;
  font-size:14px;
  color:#333;
  padding:8px 10px 9px 15px;
}
.addeventatc_dropdown   span:hover
{
  background:#f4f4f4;
  color:#000;
  text-decoration:none;
  font-size:14px;
}
.addeventatc_dropdown   .copyx
{
  height:21px;
  display:block;
  position:relative;
  cursor:default;
}
.addeventatc_dropdown   .brx
{
  height:1px;
  overflow:hidden;
  background:#e0e0e0;
  position:absolute;
  z-index:100;
  left:10px;
  right:10px;
  top:9px;
}
.addeventatc_dropdown   .frs
{
  position:absolute;
  top:5px;
  cursor:pointer;
  right:10px;
  padding-left:10px;
  font-style:normal;
  font-weight:normal;
  text-align:right;
  z-index:101;
  line-height:110%;
  background:#fff;
  text-decoration:none;
  font-size:9px;
  color:#cacaca;
}
.addeventatc_dropdown   .frs:hover
{
  color:#6d84b4;
}
