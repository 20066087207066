/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.contentlist {
  clear: both;
}
.contentlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 15px;
}
.contentlist--twocol ul:not(.contentlist__itemdescription), .contentlist--threecol ul:not(.contentlist__itemdescription) {
  display: grid;
  gap: 35px;
}
.contentlist--twocol ul:not(.contentlist__itemdescription) > li > a, .contentlist--threecol ul:not(.contentlist__itemdescription) > li > a {
  min-height: 100%;
}
.contentlist--twocol ul:not(.contentlist__itemdescription) {
  grid-template-columns: 1fr 1fr;
}
.contentlist--threecol ul:not(.contentlist__itemdescription) {
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 860px) {
  .contentlist--twocol ul:not(.contentlist__itemdescription), .contentlist--threecol ul:not(.contentlist__itemdescription) {
    display: block;
  }
  .contentlist--twocol ul:not(.contentlist__itemdescription) > li, .contentlist--threecol ul:not(.contentlist__itemdescription) > li {
    max-width: none;
  }
}
@media (max-width: 1434px) {
  .main--twocol .contentlist--twocol--twocol ul:not(.contentlist__itemdescription), .main--twocol .contentlist--threecol--twocol ul:not(.contentlist__itemdescription) {
    display: block;
  }
  .main--twocol .contentlist--twocol--twocol ul:not(.contentlist__itemdescription) > li, .main--twocol .contentlist--threecol--twocol ul:not(.contentlist__itemdescription) > li {
    max-width: none;
  }
}

.contentlist__showall {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  z-index: 0;
}
.contentlist__showall a {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color: #1e2328;
  display: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 42px;
  padding: 10px 20px;
  text-decoration: none;
  z-index: 2;
}
.contentlist__showall .arrow {
  color: #5a5e62;
  display: inline-block;
  font-size: 12px;
  padding-left: 8px;
}
.contentlist__showall::after {
  background-color: #dcdcdc;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.contentlist--limititems > .contentlist__showall {
  padding-bottom: 0;
}
.contentlist--limititems > .contentlist__showall a {
  display: inline-block;
}

.contentlist__item {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.contentlist--limititems .contentlist__item--hidden {
  display: none;
}
html.homepage .contentlist__item .hoverbarfx:hover::before {
  width: calc(100% + 30px);
}
.contentlist__item .bookmarkpage {
  margin: 15px;
  flex: none;
}
@media (max-width: 830px) {
  .contentlist__item .bookmarkpage {
    margin: 10px 0;
  }
}

.contentlist__subitem {
  line-height: 29px;
  padding-left: 29px;
  position: relative;
}
.contentlist__itemdescription--limititems .contentlist__subitem--hidden {
  display: none;
}
.contentlist__subitem a {
  color: #5a5e62;
}
.contentlist__subitem .arrow {
  display: inline-block;
  font-size: 10px;
  left: 3px;
  position: absolute;
  top: 9px;
}

.contentlist__item__showall {
  color: #5a5e62;
  display: none;
  font-size: 14px;
  margin-bottom: 15px;
  opacity: 0.7;
  text-decoration: none;
}
.contentlist__item__showall:hover {
  opacity: 1;
  text-decoration: underline;
}
.contentlist__item__showall .arrow {
  display: inline-block;
  font-size: 10px;
  padding-left: 5px;
}

.contentlist__itemdescription--limititems + .contentlist__item__showall {
  display: inline-block;
}

.contentlist__sortoptions {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;
}
@media (max-width: 1024px) {
  .contentlist__sortoptions {
    display: none;
  }
}

.contentlist__sortoptions__label {
  flex: none;
  min-width: 70px;
}

.contentlist__sortoptions__option {
  background-color: var(--color-grey-normal);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  line-height: 20px;
  margin-right: 10px;
  padding: 8px 10px;
  transition: background-color 0.2s;
}
.contentlist__sortoptions__option small {
  font-size: inherit;
  font-weight: 300;
}
.contentlist__sortoptions__option.active, .contentlist__sortoptions__option:hover {
  background-color: var(--color-black);
}

.contentlist__item__title {
  align-items: baseline;
  color: #1e2328;
  display: flex;
  font-size: 22px;
  font-weight: 700;
  justify-content: flex-start;
  line-height: 30px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 29px;
  position: relative;
  text-decoration: none;
}
@media (max-width: 1604px) {
  .contentlist__item__title {
    font-size: 1.3715710723vw;
  }
}
@media (max-width: 1458.1818181818px) {
  .contentlist__item__title {
    font-size: 20px;
  }
}
.contentlist__item__title .arrow {
  color: #aaacae;
  flex: none;
  font-size: 16px;
  left: 0;
  margin-right: 14px;
  position: absolute;
  top: 7px;
}

.contentlist__item__link {
  display: block;
  flex: 1 1 auto;
  margin-bottom: 15px;
  text-decoration: none;
}
.contentlist__item__link > span {
  display: block;
  position: relative;
}
.contentlist__item__link .title {
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 1604px) {
  .contentlist__item__link .title {
    font-size: 1.3715710723vw;
  }
}
@media (max-width: 1458.1818181818px) {
  .contentlist__item__link .title {
    font-size: 20px;
  }
}
.contentlist__item__link > span.description {
  color: #5A5E62;
  padding-top: 5px;
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.contentlist__item:not(.contentlist__item--iscategory) .contentlist__item__link .arrow {
  color: #5A5E62;
  font-size: 12px;
  top: 10px;
}

.contentlist__intro {
  overflow: hidden;
}