/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-quicklinks ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.widget-quicklinks .widget__title {
  font-size: 20px;
  padding: 0;
}
.widget-quicklinks li {
  padding-top: 15px;
}
.widget-quicklinks a {
  text-decoration: none;
  display: block;
  position: relative;
  padding-left: 30px;
  color: #5A5E62;
  font-size: 16px;
  text-decoration: none;
}
.widget-quicklinks a .arrow {
  position: absolute;
  opacity: 0.6;
  left: 0;
  top: 2px;
  transition: opacity 0.3s;
}
.widget-quicklinks a .title {
  color: var(--color-black);
  transition: opacity 0.3s;
  opacity: 0.6;
}
.widget-quicklinks a:hover .title {
  opacity: 1;
  text-shadow: 0 0 1px rgb(0, 0, 0);
}
.widget-quicklinks a:hover .arrow:not(.themecolor--fg)::before {
  content: "\e92b";
  opacity: 0.3;
}