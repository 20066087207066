/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.searchsheet {
  left: 0;
  margin-left: 78px;
}
@media (max-width: 600px) {
  .searchsheet {
    margin-left: 0;
  }
}
@media (max-width: 1258px) {
  .searchsheet .searchsheet__searchform {
    width: calc(50% + 330px);
  }
}
@media (max-width: 1009.0694626474px) {
  .searchsheet .searchsheet__searchform {
    margin-left: 20px;
    margin-right: 130px;
    width: auto;
  }
}
@media (max-width: 600px) {
  .searchsheet .searchsheet__searchform {
    margin-left: calc(50% - 460px);
    margin-right: auto;
    width: calc(50% + 330px);
  }
}
@media (max-width: 960px) {
  .searchsheet .searchsheet__searchform {
    margin-left: 90px;
    margin-right: 270px;
    width: auto;
  }
}
@media (max-width: 750px) {
  .searchsheet .searchsheet__searchform {
    margin-left: 28px;
    margin-right: 146px;
    margin-top: 10px;
  }
}
@media (max-width: 480px) {
  .searchsheet .searchsheet__searchform {
    margin-left: 22px;
    margin-right: 134px;
    margin-top: 10px;
  }
}
@media (max-width: 1009.0694626474px) {
  .searchsheet .searchsheet__quicklinks,
  .searchsheet .searchsheet__quickresults,
  .searchsheet .searchsheet__results {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
}
@media (max-width: 600px) {
  .searchsheet .searchsheet__quicklinks,
  .searchsheet .searchsheet__quickresults,
  .searchsheet .searchsheet__results {
    margin-left: auto;
    margin-right: auto;
    width: 920px;
  }
}
@media (max-width: 960px) {
  .searchsheet .searchsheet__quicklinks,
  .searchsheet .searchsheet__quickresults,
  .searchsheet .searchsheet__results {
    margin-left: 90px;
    margin-right: 90px;
    width: auto;
  }
}
@media (max-width: 750px) {
  .searchsheet .searchsheet__quicklinks,
  .searchsheet .searchsheet__quickresults,
  .searchsheet .searchsheet__results {
    margin-left: 28px;
    margin-right: 28px;
  }
}
@media (max-width: 480px) {
  .searchsheet .searchsheet__quicklinks,
  .searchsheet .searchsheet__quickresults,
  .searchsheet .searchsheet__results {
    margin-left: 22px;
    margin-right: 22px;
  }
}
.searchsheet .searchsheet__status b {
  font-weight: 600;
}
.searchsheet .inlinecta__button {
  background: var(--theme-color-cta);
}
.searchsheet .inlinecta__button:hover {
  background: var(--theme-color-cta-hover);
}
.searchsheet .ut-querymatch::before {
  background-color: var(--theme-color);
}
.searchsheet .widget__photoslide {
  display: block;
  transition: none;
}
.searchsheet .widget__title {
  font-size: 18px;
  line-height: 18px;
}