/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.profile__heading3 {
  font-size: 22px;
}
@media (max-width: 1024px) {
  .profile__heading3 {
    font-size: 2.1484375vw;
  }
}
@media (max-width: 837.8181818182px) {
  .profile__heading3 {
    font-size: 18px;
  }
}

.profile__user {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.profile__user__photo {
  flex: none;
  margin-right: 30px;
  border-radius: 50%;
}
.profile__user__photo__placeholder {
  color: var(--color-grey-light);
  font-size: 78px;
}
.profile__user__photo > img {
  border-radius: 50%;
}
.profile__user__details {
  flex: 1 1 auto;
}
.profile__user small {
  font-size: inherit;
  font-weight: 300;
}

.profile__twocol {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 30px;
}
.profile__twocol > .profile__col {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
@media (max-width: 750px) {
  .profile__twocol {
    display: block;
  }
  .profile__twocol > .profile__col {
    max-width: none;
  }
}

.profile__settingsblock {
  margin: 30px 0;
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--color-grey-lines);
  padding: 20px 25px 25px;
  z-index: 0;
  display: flex;
  flex-flow: column;
}
.profile__settingsblock--readonly {
  padding-bottom: 0;
}
.profile__settingsblock--busy::after, .profile__settingsblock--done::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 25px;
  display: block;
  font-size: inherit;
  height: 16px;
  position: absolute;
  right: 25px;
  width: 16px;
}
.profile__settingsblock--busy::after {
  content: "\e9d4";
}
.profile__settingsblock--done::after {
  content: "\e9d5";
}
.profile__settingsblock h4 {
  margin-bottom: 5px;
}
.profile__settingsblock p {
  color: var(--color-grey-normal);
}
.profile__settingsblock__spacer {
  flex: 1 1 auto;
}
h4 + .profile__settingsblock__spacer {
  margin-bottom: 25px;
}
.profile__settingsblock > *:not(.profile__settingsblock__spacer) {
  flex: none;
}

.profile__settingsblock__options--singleselect {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.profile__settingsblock__options--singleselect label {
  font-size: 15px;
  margin-right: 15px;
}
.profile__settingsblock__options--singleselect .wh-form__pulldown-styled {
  max-width: 300px;
}
.profile__settingsblock__options--singleselect .wh-form__pulldown-styled select {
  font-size: 14px;
}

.profile__subscription {
  display: inline-block;
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);
  padding: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 2px;
  transition: background-color 200ms, color 200ms;
}
.profile__subscription:not(.profile__subscription--role):hover {
  background-color: var(--color-grey-dark);
  color: #fff;
}
.profile__subscription--add, .profile__subscription--subscribed {
  cursor: pointer;
}
.profile__subscription--add {
  display: block;
}
.profile__subscription--subscribed::after {
  align-content: center;
  background-color: var(--color-dark-grey);
  color: white;
  border-radius: 50%;
  content: "\e92e";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  font-size: 10px;
  height: 18px;
  justify-content: center;
  line-height: 18px;
  margin-left: 8px;
  width: 18px;
  transition: background-color 200ms;
}
.profile__subscription--subscribed:hover::after {
  background-color: var(--color-black);
}
.profile__subscription--role {
  color: var(--color-tahiti-gold);
  background-color: rgba(236, 122, 8, 0.1);
}

.profile__addsubscriptionbutton {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  background: black;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
}

.profile__settingsblock__options--nooptions .profile__addsubscriptionbutton {
  cursor: default;
  opacity: 0.3;
}

.profile__subscriptions {
  margin-bottom: 20px;
}