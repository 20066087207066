/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-widgetblock .widget-media__infos {
  padding: 0 20px;
}

.widget-media {
  background-color: inherit;
}
.widget-media .widget__title {
  padding: 20px 0;
}
.widget-media__infos {
  font-size: 15px;
  position: relative;
}
.widget-media__infos p {
  margin: -5px 0 20px;
}
.widget-media__infos__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 300;
}
.widget-media__infos__links li + li {
  margin-top: 15px;
}
.widget-media__infos__slide {
  opacity: 0;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: opacity 0.5s;
}
.widget-media__infos__slide.active {
  opacity: 1;
}
.widget-media__infos__slide .title {
  font-weight: bold;
  text-transform: uppercase;
}
.widget-media__infos__slide p {
  margin: 5px 0 0;
}
.widget-media__infos__slide a {
  display: inline-block;
  margin-top: 15px;
}
.widget-media--wide .widget__title, .widget-media--wide .widget-media__infos__links {
  padding: 20px;
}
.widget-media--wide p {
  margin-left: 20px;
  margin-right: 20px;
}
.widget-media__container {
  position: relative;
  outline: none;
}
.widget-media__container__slides {
  position: relative;
  outline: none;
}
.widget-media__container__slides::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-media__container__slides__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  display: block;
  text-decoration: none;
}
.widget-media__container__slides__slide.active {
  opacity: 1;
  z-index: 1;
}
.widget-media__container__slides__slide img {
  vertical-align: bottom;
  width: 100%;
  height: 100%;
}
.widget-media__container__slides__slide__title {
  color: #fff;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px 23px 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  min-height: 50%;
  max-height: 100%;
  pointer-events: none;
  font-size: 18px;
  line-height: 150%;
}
@media (max-width: 1604px) {
  .widget-media__container__slides__slide__title {
    font-size: 1.1221945137vw;
  }
}
@media (max-width: 1425.7777777778px) {
  .widget-media__container__slides__slide__title {
    font-size: 16px;
  }
}
.widget-media__container__slides__slide__title .tag {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 2px;
  font-size: 22px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  line-height: 28px;
  padding: 5px 10px;
}
.widget-media__container__slides__slide__video {
  position: absolute !important; /* overwrite .wh-video css from publisher:video.css  */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-media__container__slides__slide__video::after {
  content: "";
  cursor: pointer;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent url(../../web/img/play.png) 50% 50% no-repeat;
  background-size: 15%;
  left: 0;
  top: 0;
  opacity: 0.75;
  transition: opacity 0.3s;
}
.widget-media__container__slides__slide__video:hover::after {
  opacity: 0.9;
}
.widget-media__container__slides__slide__video.wh-video::after {
  display: none !important;
}
.widget-media__container__slides__slide__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-media__container__jumpbuttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}
.widget-media__container__jumpbuttons > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 4px;
  position: relative;
}
.widget-media__container__jumpbuttons > span::before, .widget-media__container__jumpbuttons > span::after {
  content: "";
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
}
.widget-media__container__jumpbuttons > span::before {
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background-color: #fff;
}
.widget-media__container__jumpbuttons > span::after {
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border: 1px solid #fff;
  opacity: 0;
  transition: opacity 0.3s;
}
.widget-media__container__jumpbuttons > span.active::after, .widget-media__container__jumpbuttons > span:hover::after {
  opacity: 1;
}
.widget-media[data-autoplay] .widget-media__container__slides__slide__title {
  padding-right: 140px;
}
.widget-media:hover .widget-media__container__jumpbuttons > .active::after {
  animation: blink-animation 2s linear infinite;
}
.widget-media__nav {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 42px; /* Fixme width dependend */
  z-index: 2; /* keep on top of slide */
  transition: transform 0.3s;
}
.widget-media__nav--previous {
  left: 0;
}
.widget-media__nav--previous:hover > * {
  transform: translateX(-3px);
}
.widget-media__nav--next {
  right: 0;
}
.widget-media__nav--next:hover > * {
  transform: translateX(3px);
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}