/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.wh-anchor {
  scroll-margin-top: calc(15px + var(--header-top-height));
}
.wh-details__container__inner > .wh-anchor:first-child {
  scroll-margin-top: calc(100px + var(--header-top-height));
}

.main--twocol .main__deeplinks {
  display: none;
}

.main__deeplinks {
  right: 0;
  padding: 15px 20px 10px 10px;
  text-align: right;
  border-radius: 5px 0 0 5px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 170px;
  position: absolute;
  top: 50px;
  z-index: 1;
}
@media (max-width: 1135px) {
  .main__deeplinks {
    display: none;
  }
}
.main__deeplinks--stickymain {
  position: fixed;
  margin-top: 40px;
}
.main__deeplinks--stickyfooter {
  bottom: 50px;
  position: absolute;
  top: auto;
}
.main__deeplinks__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.main__deeplinks__list a {
  color: #aaacae;
  display: block;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  min-height: 20px;
  margin-bottom: 5px;
  padding: 3px 0;
  text-decoration: none;
  position: relative;
}
.main__deeplinks__list a > span {
  display: block;
  max-width: calc(100% - 20px);
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main__deeplinks__list a--active, .main__deeplinks__list a.isvisible:not(.isvisible--hide), .main__deeplinks__list a:hover {
  color: #162731;
}
.main__deeplinks__list a:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 3px;
  right: 0;
  content: "\e941";
  display: inline-block;
}
.main__deeplinks__list a.isbefore:after {
  content: "\e940";
}
.main__deeplinks__list a.isvisible:after {
  content: "\e944";
}