/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.wh-form__buttongroup {
  text-align: right;
  padding: 30px 0 20px;
}
.wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}

.wh-form__button:hover {
  opacity: 0.8;
}
.wh-form__button.bg_ctacolors:hover {
  opacity: 1;
}
.wh-form__button + .wh-form__button {
  margin-left: 15px;
}

.button, .wh-form__button, .inlinecta__button {
  text-decoration: none;
  font: inherit;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  height: 40px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 20px;
  padding: 10px 25px;
  position: relative;
  background-color: #1e2328;
  color: #fff;
  text-align: left;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.button__icon, .wh-form__button__icon, .inlinecta__button__icon {
  font-size: 16px;
  color: inherit;
  opacity: 0.6;
}
.button__icon:first-child, .wh-form__button__icon:first-child, .inlinecta__button__icon:first-child {
  margin-right: 10px;
}
.button .wh-form__buttonlabel, .button__label, .wh-form__button .wh-form__buttonlabel, .wh-form__button__label, .inlinecta__button .wh-form__buttonlabel, .inlinecta__button__label {
  position: relative;
  display: inline-block;
}
.button.bg_ctacolors::before, .wh-form__button.bg_ctacolors::before, .inlinecta__button.bg_ctacolors::before {
  border-radius: 20px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s;
}
.button.bg_ctacolors:hover::before, .wh-form__button.bg_ctacolors:hover::before, .inlinecta__button.bg_ctacolors:hover::before {
  opacity: 0.2;
}
.button--previous, .wh-form__button--previous, .inlinecta__button--previous {
  font-weight: normal;
  font-size: 15px;
  text-transform: none;
  color: inherit;
  padding: 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  background-color: transparent;
  opacity: 0.5;
  transition: opacity 0.3s;
}
.button--previous:hover, .wh-form__button--previous:hover, .inlinecta__button--previous:hover {
  opacity: 1;
}
.button--previous .wh-form__buttonlabel, .wh-form__button--previous .wh-form__buttonlabel, .inlinecta__button--previous .wh-form__buttonlabel {
  text-decoration: underline;
}
.button--previous::before, .wh-form__button--previous::before, .inlinecta__button--previous::before {
  font-size: 16px;
  content: "\e92a";
  font-size: 16px;
  padding-right: 8px;
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button--outline, .wh-form__button--outline, .inlinecta__button--outline {
  border: 2px solid var(--color-grey-lines);
  border-radius: 2px;
  font-size: 14px;
  padding: 7px 12px;
  font-weight: normal;
  background-color: transparent;
  color: var(--color-grey-dark);
  text-transform: none;
  height: 38px;
  transition: background-color 0.3s;
}
.button--outline:hover, .button--outline.active, .wh-form__button--outline:hover, .wh-form__button--outline.active, .inlinecta__button--outline:hover, .inlinecta__button--outline.active {
  background-color: var(--color-grey-lines);
}

.inlinecta__button {
  font-size: 18px;
  margin-top: 16px;
}