/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-contact {
  background-color: #fff;
  padding: 20px 20px 15px;
}
.widget-contact .widget__title {
  padding-bottom: 20px;
}
.widget-contact__tabs {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
.widget-contact__tabs__labels {
  display: flex;
  font-size: 15px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.widget-contact__tabs__content {
  display: flex;
  transition: transform 0.3s;
  padding-top: 15px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  transform: translateX(0);
}
.widget-contact__tabs .label {
  padding: 5px 0;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.6;
  padding-right: 15px;
  position: relative;
}
.widget-contact__tabs .label:last-child {
  padding-right: 0;
}
.widget-contact__tabs .label:hover {
  opacity: 1;
}
.widget-contact__tabs .content {
  flex: none;
  width: 100%;
}
.widget-contact__tabs .label::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 0;
  width: 100%;
  max-width: calc(100% - 15px);
  border-bottom: 1px solid var(--color-black);
  transition: width 0.3s, left 0.3s;
}
.widget-contact__tabs .label:last-child::after {
  max-width: 100%;
}
.widget-contact__tabs--visit .label[data-tab=mail]::after {
  left: 100%;
  width: 0;
}
.widget-contact__tabs--visit .label[data-tab=visit]::after {
  left: 0;
  width: 100%;
}
.widget-contact__tabs--mail .label[data-tab=visit]::after {
  left: -16px;
  width: 0;
}
.widget-contact__tabs--mail .label[data-tab=mail], .widget-contact__tabs--visit .label[data-tab=visit] {
  opacity: 1;
}
.widget-contact__tabs--visit .widget-contact__tabs__content {
  transform: translateX(-100%);
}
.widget-contact a {
  display: block;
  position: relative;
}
.widget-contact__line {
  display: flex;
  padding: 10px 50px 10px 0;
  border-top: 1px solid var(--color-grey-lines);
  border-bottom: 1px solid var(--color-grey-lines);
  text-decoration: none;
  color: inherit;
}
.widget-contact__line.hoverbarfx::before {
  z-index: 0;
}
.widget-contact__line.hoverbarfx:hover::before {
  width: calc(100% + 10px);
}
.widget-contact__line + .widget-contact__line {
  border-top: 0 none;
}
.widget-contact__line .text {
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 300;
}
.widget-contact__line .icon {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 24px;
  margin-top: -12px;
  width: 24px;
  color: #aaacae;
  transition: color 0.3s;
}
.widget-contact__line:hover .icon {
  color: var(--color-black);
}
.widget-contact__plainlink {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
  transition: color 0.3s;
  color: #5a5e62;
}
.widget-contact__plainlink:hover {
  color: var(--color-black);
}
.widget-contact__socialmedia {
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-contact__socialmedia a {
  font-size: 24px;
  height: 24px;
  min-width: 24px;
  padding: 0 8px;
  transition: color 0.3s;
  color: #aaacae;
  text-decoration: none;
}
.widget-contact__socialmedia a:hover {
  color: var(--color-black);
}