/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-inlinecta {
  max-width: calc(100vw - 40px);
}
.widget-inlinecta__header {
  border: 1px solid var(--color-grey-lines);
  background-color: var(--color-grey-light);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 17px 25px 17px 5px;
}
.widget-inlinecta__title {
  font-size: 26px;
  line-height: 100%;
  font-weight: 300;
  text-transform: uppercase;
  flex: none;
  max-width: calc(100% - 20px);
  margin-left: 20px;
}
.widget-inlinecta__title > *:last-child {
  margin-bottom: 0;
}
.widget-inlinecta__title b {
  font-weight: 700;
}
.widget-inlinecta__filler {
  flex: 1 1 0px;
}
.widget-inlinecta__button {
  display: inline-block;
  max-width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 30px;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 8px 0 8px 20px;
  position: relative;
  text-transform: uppercase;
}
.widget-inlinecta__button > span {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.widget-inlinecta__button::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s;
}
.widget-inlinecta__button:hover::before {
  opacity: 0.15;
}
.widget-inlinecta__description {
  font-style: italic;
  position: relative;
  padding: 20px 25px 20px 45px;
  border: 1px solid var(--color-grey-lines);
  background-color: var(--color-grey-light);
  margin-top: -1px;
  min-height: 100px;
  font-size: 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}
.widget-inlinecta__description > *:last-child {
  margin-bottom: 0;
}
.widget-inlinecta__description__icon {
  position: absolute;
  left: -23px;
  top: 20px;
  font-size: 64px;
}