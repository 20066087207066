/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
html.homepage .widget-trendingtopics .widget__title {
  font-size: 22px;
  line-height: 42px;
  padding: 0;
}
html.homepage .widget-trendingtopics .widget__header__button {
  font-size: 18px;
  font-weight: bold;
  line-height: 42px;
}
html.homepage .widget-trendingtopics .widget__header__button::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #aaacae;
  content: "\e92b";
  display: inline-block;
  font-size: 10px;
  padding-left: 16px;
  position: relative;
  bottom: 1px;
}
.widget-trendingtopics a {
  text-decoration: none;
  color: inherit;
}
.widget-trendingtopics ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
html.homepage .widget-trendingtopics ul {
  display: grid;
  grid-template-columns: [col1] 1fr 68px [col2] 1fr;
}
@media (max-width: 830px) {
  html.homepage .widget-trendingtopics ul {
    grid-template-columns: [col1 col2] 1fr;
  }
}

.widget-trendingtopics__item {
  margin: 20px 0 0;
  background-color: #fff;
}
html.homepage .widget-trendingtopics__item {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.13);
  grid-column: col1/span 1;
  margin-top: 44px;
}
html.homepage .widget-trendingtopics__item:nth-child(2n) {
  grid-column: col2/span 1;
}
.widget-trendingtopics__item > a {
  display: flex;
  height: 100%;
}
.widget-trendingtopics__item > a > div {
  flex: 1 1 50%;
  max-width: 50%;
}

.widget-trendingtopics__item__text {
  border-bottom: 4px solid var(--color-bondi-blue);
  padding: 25px 25px 20px;
  line-height: 150%;
  font-size: 14px;
}
.widget-trendingtopics__item__text > span {
  display: block;
}

.widget-trendingtopics__item__title {
  font-size: 17px;
}

.widget-trendingtopics__item__description {
  padding-top: 10px;
  color: var(--color-dark-grey);
}

.widget-trendingtopics__item__date {
  font-size: 13px;
}

@media (max-width: 1024px) {
  .widget-trendingtopics__item > a > .widget-trendingtopics__item__image {
    flex: none;
    width: 20vw;
  }
  .widget-trendingtopics__item > a > .widget-trendingtopics__item__text {
    padding: 15px 15px 15px;
    flex: 1 1 auto;
    max-width: none;
  }
}
@media (max-width: 550px) {
  .widget-trendingtopics__item > a > .widget-trendingtopics__item__image {
    width: 110px;
  }
}
@media (max-width: 370px) {
  .widget-trendingtopics__item > a > .widget-trendingtopics__item__image {
    display: none;
  }
}