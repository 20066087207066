/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/* radio / checkbox styling */
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 6px;
  width: 22px;
  max-width: 22px;
  flex: 0 0 22px;
  height: 22px;
  border: 1px solid #cbcbcb;
  background-color: #fff;
  border-radius: 2px;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  color: #cbcbcb;
  font-size: 16px;
  display: block;
  padding: 2px;
  width: 20px;
  height: 20px;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-styledinput input[type=radio]:hover + label::after,
.wh-styledinput input[type=checkbox]:hover + label::after {
  opacity: 0.2;
}
.wh-styledinput input[type=radio]:checked + label::after,
.wh-styledinput input[type=checkbox]:checked + label::after {
  opacity: 1;
}
.wh-styledinput input[type=radio]:checked + label,
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: #1e2328;
}
.wh-styledinput input[type=radio]:focus + label, .wh-styledinput input[type=radio]:hover + label,
.wh-styledinput input[type=checkbox]:focus + label,
.wh-styledinput input[type=checkbox]:hover + label {
  border-color: grey;
  box-shadow: 0 none;
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #C5C5C5;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #cbcbcb;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}

.wh-form__optiondata > * {
  padding-left: 15px;
}

.wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}

.wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}

input[type=radio] + label {
  border-radius: 50% !important;
}
input[type=radio] + label::after {
  border-radius: 50%;
  border: 6px solid #fff;
  background-color: #1e2328;
  opacity: 0;
}
input[type=radio]:checked + label::after {
  border-color: #1e2328;
  background-color: #fff;
}
input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
input[type=radio][disabled]:checked + label::after {
  background-color: #C5C5C5;
}

input[type=checkbox] + label::after {
  content: "\e931" !important;
  color: #1e2328;
  opacity: 0;
}
input[type=checkbox]:checked + label::after {
  background-color: #1e2328;
  color: #fff;
}
input[type=checkbox][disabled] + label::after {
  content: "";
}
input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: #C5C5C5;
}

.wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #cd202c;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}