/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-horizontalbanner {
  margin-top: 40px;
  margin-bottom: 40px;
}
.widget-horizontalbanner__inner {
  display: flex;
  text-decoration: none;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  min-height: 100px;
}
.widget-horizontalbanner__inner > span {
  display: block;
  flex: 1 1 50%;
  max-width: 50%;
}
.widget-horizontalbanner a:hover {
  opacity: 0.85;
}
.widget-horizontalbanner__image {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.widget-horizontalbanner__details {
  padding: 30px;
}
.widget-horizontalbanner__title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
.widget-horizontalbanner__description {
  padding-top: 10px;
  display: block;
  font-size: 14px;
  line-height: 160%;
  color: #5A5E62;
}