/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-findut {
  background-color: var(--color-grey-lighter);
}
.widget-findut__title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 15px;
}
.widget-findut p {
  color: var(--color-grey-dark);
}
.widget-findut__form {
  display: flex;
  margin-top: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.widget-findut__form > .icon {
  flex: none;
  width: 40px;
  text-align: right;
  font-size: 24px;
}
.widget-findut__form input, .widget-findut__form button {
  height: 66px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0 none;
  background-color: transparent;
}
.widget-findut__form input {
  flex: 1 1 auto;
  font-size: 15px;
  padding: 10px 15px;
}
.widget-findut__form button {
  flex: none;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 66px;
  cursor: pointer;
  z-index: 0;
  background-color: var(--theme-color-cta);
}
.widget-findut__form button.themecolor--bg::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
}
.widget-findut__form button.themecolor--bg:hover::before {
  opacity: 0.2;
}
@media (max-width: 1024px) {
  .main__colright .widget-findut {
    background-color: transparent;
  }
  .main__colright .widget-findut__form {
    border: 1px solid var(--color-grey-light);
  }
}