/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-person {
  font-size: 18px;
  font-weight: 300;
  /* contactperson */
  /* foldable */
}
.widget-person + .widget-person {
  margin-top: -31px;
}
.widget-person .wh-details__summary > .hoverbarfx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-person + .widget-person--block {
  margin-top: -10px;
}
.widget-person__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media (max-width: 600px) {
  .widget-person__header {
    flex-flow: column;
  }
  .widget-person__header .widget-person__name {
    padding-top: 20px;
    min-width: 100%;
  }
}
.widget-person__title {
  color: #aaacae;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex: none;
  margin-bottom: 10px;
}
.widget-person__title::after {
  background: #dcddde;
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 9px;
}
.widget-person a {
  color: inherit;
  text-decoration: none;
}
.widget-person__footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-grey-lines);
}
.widget-person__footer > * {
  position: relative;
  display: block;
  flex: none;
  min-width: calc(50% - 12px);
  max-width: calc(50% - 12px);
  padding: 15px 35px 15px 0;
  border-top: 1px solid var(--color-grey-lines);
}
.widget-person__footer > * .text {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-person__footer > * .icon {
  font-size: 24px;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #aaacae;
  margin-top: -12px;
  transition: color 0.3s;
}
.widget-person__footer > *.hoverbarfx:hover .icon {
  color: inherit;
}
@media (max-width: 600px) {
  .widget-person__footer {
    display: block;
  }
  .widget-person__footer > * {
    max-width: none;
  }
}
.widget-person__photo {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  flex: none;
}
.widget-person__photo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 50%;
}
.widget-person__bio {
  padding-top: 15px;
}
.widget-person__bio > *:last-child {
  margin-bottom: 0;
}
.widget-person__bio a {
  text-decoration: underline;
}
.widget-person__fullname {
  text-transform: uppercase;
  font-weight: bold;
}
.widget-person__callback {
  background-color: #f7f8f8;
  font-weight: 400;
}
.widget-person__callback__text {
  font-style: italic;
  color: #5a5e62;
  font-size: 13px;
  padding-bottom: 5px;
}
.widget-person__callback__form {
  display: flex;
}
.widget-person__callback__form > * {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
}
.widget-person__callback__form > *:focus {
  outline: none;
}
.widget-person__callback__form input {
  border: 1px solid var(--color-grey-lines);
  flex: 1 1 100%;
  padding: 9px 10px;
  line-height: 20px;
  font-size: 15px;
  transition: border-color 0.3s;
}
.widget-person__callback__form input:focus {
  border-color: var(--color-grey-dark);
}
.widget-person__callback__form button {
  flex: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  opacity: 0.5;
  transition: opacity 0.3s;
  background-color: transparent;
  border: 0 none;
  padding: 0;
  cursor: pointer;
}
.widget-person__callback__form button:hover, .widget-person__callback__form button:focus {
  opacity: 1;
}
.widget-person__callback--submitting {
  opacity: 0.4;
}
.widget-person__callback--submitting * {
  pointer-events: none;
}
.widget-person__callback--submitted .widget-person__callback__text, .widget-person__callback--submitted .widget-person__callback__form {
  display: none;
}
.widget-person__callback__successtext {
  display: none;
  font-size: 15px;
}
.widget-person__callback--submitted .widget-person__callback__successtext {
  display: block;
}
.widget-person--contactperson {
  padding: 20px 20px 0;
  position: relative;
  background-color: #fff;
  z-index: 0;
}
.widget-person--contactperson .widget-person__callback {
  border-top: 0 none !important;
  margin: 0 -20px;
  padding: 0 20px 20px;
}
.widget-person--contactperson .widget-person__callback::before {
  content: "";
  display: block;
  border-top: 1px solid var(--color-grey-lines);
  height: 20px;
}
.widget-person--contactperson .widget-person__header {
  flex-flow: column;
}
.widget-person--contactperson .widget-person__photo {
  margin-bottom: 30px;
}
.widget-person--foldable .widget-person__wrapper {
  border-top: 1px solid var(--color-grey-lines);
  border-bottom: 1px solid var(--color-grey-lines);
}
.widget-person--foldable .widget-person__wrapper .wh-details__summary {
  padding: 15px 50px 15px 0;
}
.widget-person--foldable .widget-person__wrapper .wh-details__container__inner {
  padding-bottom: 0;
}
.widget-person--foldable .widget-person__wrapper .widget-person__header {
  padding: 0;
}
.widget-person--foldable .widget-person__photo {
  width: 60px;
  height: 60px;
  margin-left: 20px;
}
.widget-person--foldable .widget-person__header {
  margin-bottom: 0;
  font-weight: 300;
}
.widget-person--contactperson .widget-person__footer, .widget-person--foldable .widget-person__footer {
  display: block;
  border-bottom: 0 none;
}
.widget-person--contactperson .widget-person__footer > *, .widget-person--foldable .widget-person__footer > * {
  min-width: 0;
  max-width: none;
  border-top: 1px solid var(--color-grey-lines);
}