/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
footer .feedback {
  position: relative;
}
footer .feedback__description {
  font-size: 14px;
  line-height: 150%;
  font-style: italic;
  padding-top: 20px;
  padding-bottom: 15px;
}
footer .feedback__toggle {
  opacity: 1;
  transition: opacity 300ms;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
footer .feedback__toggle input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
footer .feedback__toggle .question {
  padding-right: 20px;
}
footer .feedback__toggle .choices {
  display: flex;
  height: 47px;
}
footer .feedback__toggle .choices label {
  cursor: pointer;
  display: block;
  margin-left: 3px;
}
footer .feedback__toggle .choices span {
  width: 63px;
  display: block;
  text-align: center;
  line-height: 47px;
  border-radius: 2px;
  transition: color 0.2s, background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
footer .feedback__toggle input[value=yes] + span::before {
  display: inline-block;
  content: "Yes";
}
footer .feedback__toggle input[value=no] + span::before {
  display: inline-block;
  content: "No";
}
footer .feedback__toggle input:checked + span {
  background-color: rgb(255, 255, 255);
  color: #0094B3;
}
footer .feedback__toggle input[value=yes]:checked + span::before {
  font-size: 20px;
  position: relative;
  top: 4px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e931";
}
footer .feedback--send .feedback__toggle {
  display: none;
}
footer .feedback--send .wh-form__page {
  margin-top: 20px;
  font-weight: bold;
}
footer .feedback--send .wh-form__buttongroup {
  display: none;
}
footer .feedback .question > span {
  display: none;
}
footer .feedback .wh-styledinput input, footer .feedback .wh-styledinput textarea {
  color: var(--color-black);
}
footer .feedback .wh-styledinput .wh-form__buttongroup {
  padding-top: 15px;
  padding-bottom: 0;
}
footer .feedback .wh-styledinput .wh-form__error {
  color: #ffaaaa;
}
footer .feedback .wh-styledinput .wh-form__fieldgroup--error input,
footer .feedback .wh-styledinput .wh-form__fieldgroup--error textarea {
  background-color: #fff6f6;
}
footer .feedback .wh-styledinput .wh-form__button {
  background-color: #fff;
  color: #0094B3;
}
footer .feedback__positive {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms;
}
footer .feedback--ispositive .feedback__toggle, footer .feedback--isnegative .feedback__toggle {
  opacity: 0;
  pointer-events: none;
}
footer .feedback--isnegative .feedback__toggle {
  height: 0;
}
footer .feedback--ispositive .feedback__positive {
  opacity: 1;
}
footer .feedback__formholder {
  display: none;
  min-width: 320px;
  max-width: calc(100vw - 40px);
}
footer .feedback.feedback--isnegative .feedback__formholder {
  display: block;
}