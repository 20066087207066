html
{
  color: #1e2328;
  font: 15px "Univers Next W02", Arial, Helvetica, sans-serif;
}

h1, h2, h3
{
  text-transform: uppercase;
  line-height: 100%;
}

h1
{
  font-size: 42px;
}
h1 small
{
  font-weight: 500;
  display: block;
  font-size: inherit;
  line-height: inherit;
}

h2
{
  font-size: 26px;
  margin-bottom: 30px;
}

h3
{
  font-size: 18px;
  line-height: 130%;
  text-transform: uppercase;
}
h4
{
  font-size: 15px;
  font-weight: 600;
  line-height: 160%;
}

  * + h2.heading2
, * + h3.heading3
{
  margin-top: 50px;
}

* + h4.heading4
{
  margin-top: 25px;
}

a
{
  color: inherit;
  word-break: break-word;
}

p.introtext
{
  font-size: 26px;
  font-weight: 300;
  line-height: 100%;
  text-transform: uppercase;
}

p b
{
  font-weight: 600;
}

p.introtext b
{
  font-weight: 700;
}

p
{
  margin-bottom: 25px;
  line-height: 160%;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
  list-style-type: none;
}
  ul.unordered
, ol.ordered
{
  margin-bottom: 25px;
}
  article * ul.unordered
, article * ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 10px 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  color: #000;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ul.unordered > li
, ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 15px;
  padding-bottom: 5px;
  min-height: 15px;
}
  ul.unordered > li::before
, ul.unordered ul > li::before
, ol.ordered ul > li::before
{
  padding-top: 2px;
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 8px;
  font-family: Arial, Helvetica, sans-serif;
}
ul.unordered ul > li::before
{
  content: "\25cb"; /* outline circle */
}
ul.unordered ul ul li:before
{
  content: "\25a0"; /* solid square */
}
ul.unordered ul ul ul li:before
{
  content: "\25a1"; /* outline square */
}

  ol.ordered
, li ol
{
  counter-reset: numlist;
}
ol.ordered li:before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}
ol.ordered ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-alpha) ".";
}
ol.ordered ol ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-roman) ".";
}


/* Table styling */
.wh-rtd__tablewrap
{
  margin-bottom: 30px;
}

  .wh-rtd__tablewrap.widewithlines
, .wh-rtd__tablewrap.widezebra
{
  max-width: 100%;
}

.wh-rtd__tablewrap__scrollcontainer
{
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.wh-rtd__tablewrap__scrollcontainer.ps--active-x
{
  padding-bottom: 20px;
}
.wh-rtd__tablewrap__scrollcontainer .ps__rail-x
{
  opacity: 1 !important;
  background-color: #d2d2d2 !important;
  height: 4px !important;
}
.wh-rtd__tablewrap__scrollcontainer .ps__thumb-x
{
  background-color: #666 !important;
  border-radius: 0 !important;
  height: 4px !important;
  bottom: 0px !important;
}

.wh-rtd__tablecell
{ /* for small font use more readable font see issue #1113 */
  font-family: Arial, Helvetica, sans-serif;
}
/* except headings */
.wh-rtd__tablecell h1, .wh-rtd__tablecell h2, .wh-rtd__tablecell h3, .wh-rtd__tablecell h4
{
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
}

  table.withlines .wh-rtd__tablecell
, table.widewithlines .wh-rtd__tablecell
, table.zebra .wh-rtd__tablecell
, table.widezebra .wh-rtd__tablecell
{
  border-bottom: 1px solid #DCDDDE;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  font-weight: normal;
}
  table.withlines th, table.withlines td
, table.widewithlines th, table.widewithlines td
, table.zebra th, table.zebra td
, table.widezebra th, table.widezebra td
{
  padding: 11px 10px;
}
  .wh-rtd__table th > *:first-child
, .wh-rtd__table td > *:first-child
{
  margin-top: 0;
}
  .wh-rtd__table th > *:last-child
, .wh-rtd__table td > *:last-child
{
  margin-bottom: 0;
}
  table.withlines .wh-rtd--hasrowheader th
, table.withlines .wh-rtd--hascolheader th
, table.withlines .wh-rtd--hascolheader td
, table.widewithlines .wh-rtd--hasrowheader th
, table.widewithlines .wh-rtd--hascolheader th
, table.widewithlines .wh-rtd--hascolheader td
, table.zebra .wh-rtd--hasrowheader th
, table.zebra .wh-rtd--hascolheader th
, table.zebra .wh-rtd--hascolheader td
, table.widezebra .wh-rtd--hasrowheader th
, table.widezebra .wh-rtd--hascolheader th
, table.widezebra .wh-rtd--hascolheader td
, table.withlines .wh-rtd--hasrowheader ~ tr th
, table.widewithlines .wh-rtd--hasrowheader ~ tr th
, table.zebra .wh-rtd--hasrowheader ~ tr th
, table.widezebra .wh-rtd--hasrowheader ~ tr th
{
  color: #5A5E62;
  font-size: 13px;
}

  table.withlines .wh-rtd__tablecell
, table.zebra .wh-rtd__tablecell
{
  font-size: 13px;
}

  table.withlines .wh-rtd--hasrowheader th
, table.withlines .wh-rtd--hascolheader td
, table.widewithlines .wh-rtd--hasrowheader th
, table.widewithlines .wh-rtd--hascolheader td
, table.zebra .wh-rtd--hasrowheader th
, table.zebra .wh-rtd--hascolheader td
, table.widezebra .wh-rtd--hasrowheader th
, table.widezebra .wh-rtd--hascolheader td
, table.withlines .wh-rtd--hasrowheader ~ tr th
, table.widewithlines .wh-rtd--hasrowheader ~ tr th
, table.zebra .wh-rtd--hasrowheader ~ tr th
, table.widezebra .wh-rtd--hasrowheader ~ tr th
{
  border-right: 1px solid #DCDDDE;
}
.wh-rtd__tablecell.wh-rtd__tablecell.greencontent
{
  color: #3F9C35;
  background-color: rgba(63, 156, 53, 0.03);
  border-bottom: 1px solid #3F9C35;
}
.wh-rtd__tablecell.wh-rtd__tablecell.redcontent
{
  color: #EC1A38;
  background-color: rgba(236, 26, 56, 0.03);
  border-bottom: 1px solid #EC1A38;
}
table.widezebra tr:nth-of-type(2n)
{
  background: #f0f1f2;
}
table.widezebra tr:nth-of-type(2n+1)
{
  background: #ffffff;
}
