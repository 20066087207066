/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
:root {
  --header-top-height: 78px;
}

.header-top {
  min-height: var(--header-top-height);
  position: relative;
  z-index: 5;
}
.header-top .alignhelper {
  display: none;
  flex: 1 1 auto;
}
.header-top__menutoggle {
  display: none;
  width: 60px;
  height: 60px;
  font-size: 20px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  transition: color 300ms, background-color 300ms;
  cursor: pointer;
}
.header-top__menutoggle:hover, .header-top__menutoggle:focus, html.showmobilemenu .header-top__menutoggle {
  background-color: var(--color-black);
  color: #fff;
}
html.showmobilemenu .header-top__menutoggle__show {
  display: none;
}
.header-top__menutoggle__hide {
  display: none;
}
html.showmobilemenu .header-top__menutoggle__hide {
  display: inline-block;
}
.header-top__inner {
  position: fixed;
  left: 78px;
  right: 0;
  top: 0;
  padding-right: 0;
}
.header-top__contentwrapper {
  background-color: #fff;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-lines);
}
.header-top__sitetitle {
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  padding-left: 35px;
  padding-right: 35px;
}
.header-top__services {
  position: relative;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-top__services a {
  display: block;
  text-decoration: none;
}
.header-top__services__selected {
  min-height: 62px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  background-color: var(--color-grey-light);
  padding: 15px;
}
.header-top__services__selected i {
  font-size: 18px;
}
.header-top__services__selected > * + * {
  margin-left: 15px;
}
.header-top__services__selected a {
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  color: #fff;
  padding: 0 10px;
  background-color: var(--color-grey-dark);
  line-height: 30px;
}
.header-top__services__selected a i {
  margin-left: 7px;
  display: inline-block;
  width: 14px;
  height: 14px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  background-color: #fff;
  border-radius: 50%;
  color: var(--color-grey-dark);
}
.header-top__services__select {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}
.header-top__services__select > div + * {
  opacity: 0.3;
  transition: opacity 300ms;
  padding-left: 12px;
}
.header-top__services__select i {
  font-size: 12px;
}
.header-top__services__select:hover > * {
  opacity: 1 !important;
}
.header-top__services:focus-within .header-top__services__select > * {
  opacity: 1 !important;
}
.header-top__services:focus-within .header-top__services__foldout {
  max-height: 100vh;
}
.header-top__services__foldout {
  position: absolute;
  top: 100%;
  left: 0;
  width: 380px;
  max-width: 100vw;
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}
.header-top__services__foldout .tag {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 2px;
  flex: none;
  font-size: 12px;
  min-width: 35px;
}
.header-top__services__foldout .title {
  flex: 1 1 auto;
  max-width: calc(100% - 45px);
}
.header-top__services__foldout a {
  padding: 7px 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.header-top__services__foldout a:hover .title {
  text-underline-position: under;
  text-decoration: underline;
}
.header-top__services__foldout ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  padding: 15px 20px;
}
.header-top__right {
  display: flex;
}
.header-top__right__button {
  height: 78px;
  min-width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color-grey-lines);
  position: relative;
}
.header-top__right__button i {
  font-size: 18px;
}
.header-top__right__button--favorites i {
  font-size: 16px;
}
.header-top__right__button[tabindex] {
  cursor: pointer;
}
@media (max-width: 830px) {
  .header-top__right__button--search {
    display: none;
  }
}
.header-top__right .favorites-mobilenav {
  display: none;
  max-height: 0;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  white-space: nowrap;
  transition: max-height 300ms;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.17);
}
.header-top__right .favorites-mobilenav__inner {
  padding: 20px;
}
.header-top__right .favorites-mobilenav .bookmarkpage {
  margin-bottom: 15px;
}
.header-top__right .favorites-mobilenav i {
  color: #aaacae;
  flex: none;
  display: inline-block;
  padding-right: 14px;
  position: relative;
  top: -1px;
}
.header-top__right .favorites-mobilenav__item--manage {
  display: flex;
  align-items: center;
}
.header-top__right .favorites-mobilenav__item--manage:hover span {
  text-decoration: underline;
}
@media (max-width: 830px) {
  .header-top__right .favorites-mobilenav {
    display: block;
  }
}
@media (max-width: 830px) {
  .header-top__right__button--favorites:hover .favorites-mobilenav, .header-top__right__button--favorites:focus .favorites-mobilenav, .header-top__right__button--favorites:focus-within .favorites-mobilenav {
    max-height: 100vh;
  }
}
.header-top__right__button:focus-within .header-top__profile__foldout {
  max-height: 100vh;
}
.header-top__profile {
  width: 100%;
}
.header-top__profile__thumb {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-size: cover;
  text-decoration: none;
  width: 44px;
  height: 44px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 50%;
  color: #fff;
  text-transform: uppercase;
}
.header-top__profile__thumb .placeholder {
  font-size: 20px;
}
.header-top__profile__role {
  position: absolute;
  font-size: 11px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 1px;
  bottom: 7px;
  right: 7px;
  color: #fff;
}
.header-top__profile__foldout {
  position: absolute;
  right: 0;
  top: 100%;
  width: calc(300% + 1px);
  overflow: hidden;
  transition: max-height 300ms;
  max-height: 0;
  cursor: default;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}
.header-top__profile__foldout__inner {
  background-color: #fff;
}
.header-top__profile__foldout__roles {
  background-color: var(--color-grey-lighter);
  border-bottom: 1px solid var(--color-grey-lines);
  padding: 15px 0 10px;
}
.header-top__profile__foldout__roles__heading {
  padding: 10px 25px;
  position: relative;
}
.header-top__profile__foldout__roles__heading::after {
  position: absolute;
  opacity: 0.6;
  right: 25px;
  top: 12px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92d";
  font-size: 12px;
}
.header-top__profile__foldout ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header-top__profile__foldout li {
  cursor: pointer;
  padding: 8px 25px 8px 45px;
  opacity: 0.5;
  transition: opacity 300ms;
}
.header-top__profile__foldout li[hidden] {
  display: none;
}
.header-top__profile__foldout li:hover, .header-top__profile__foldout li:focus {
  opacity: 1;
}
.header-top__profile__foldout a {
  text-decoration: none;
  display: block;
  padding: 15px 25px;
}
.header-top__profile__foldout a:hover {
  text-decoration: underline;
}
@media (max-width: 830px) {
  .header-top {
    min-height: 60px;
  }
  .header-top__contentwrapper {
    height: 60px;
  }
  .header-top .alignhelper {
    display: block;
  }
  .header-top__menutoggle {
    display: flex;
  }
  .header-top__inner {
    left: 0;
  }
  .header-top__sitetitle {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-top__services {
    display: none;
  }
  .header-top__right__button {
    height: 60px;
    min-width: 60px;
    border-left: 0 none;
  }
  .header-top__right__button--search {
    display: none;
    border-left: 1px solid var(--color-grey-lines);
  }
  html.showmobilemenu .header-top__right__button {
    display: none;
  }
  html.showmobilemenu .header-top__right__button--search {
    display: flex;
  }
  .header-top__profile__thumb {
    width: 40px;
    height: 40px;
  }
}