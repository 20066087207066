/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-download {
  position: relative;
  padding: 25px;
  font-size: 15px;
  line-height: 165%;
  z-index: 0;
}
.widget-download a {
  margin-top: 30px;
  text-transform: uppercase;
}
.widget-download::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-tahiti-gold);
  opacity: 0.05;
  z-index: -1;
}
.widget-download__title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 20px;
}
.widget-download__description {
  color: var(--color-tahiti-gold);
}
.widget-download__description + .widget-download__filename {
  padding-top: 30px;
}
.widget-download .button {
  background-color: var(--color-tahiti-gold);
  color: #fff;
}