/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
wh-fileedit {
  --wh-button-border: none;
  --wh-button-border-radius: 15px;
  --wh-button-background-color: var(--theme-color-cta);
  --wh-button-color: #ffffff;
}
wh-fileedit::part(filename) {
  color: var(--color-black);
  background-color: var(--color-grey-light);
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  height: 30px;
  padding: 4px 10px;
}
wh-fileedit::part(button selectbutton) {
  font-weight: var(--weight-bold-condensed);
  text-transform: uppercase;
}
wh-fileedit::part(button deletebutton) {
  background-color: var(--theme-color-cta);
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(27, 27, 27, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(27, 27, 27, 0.8) 50%, rgba(27, 27, 27, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}