/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.aside-left {
  position: relative;
  width: 78px;
  flex: none;
  background-color: var(--color-black);
  color: #fff;
  z-index: 4;
  padding: 78px 0;
}
@media (max-width: 830px) {
  .aside-left {
    display: none;
  }
}
.aside-left a {
  color: inherit;
  text-decoration: none;
}
.aside-left__ut {
  position: fixed;
  height: 78px;
  left: 0;
  top: 0;
  width: 78px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 29px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aside-left__ut img {
  width: 35px;
  height: auto;
}
.aside-left__nav {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 78px;
  top: 50vh;
  transform: translateY(-50%);
}
.aside-left__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.aside-left__nav a {
  text-decoration: none;
}
.aside-left__nav__item {
  flex: none;
  position: relative;
  /* Just some extra mouseover area to prevent flicker when moving mouse to foldout */
}
.aside-left__nav__item::before {
  z-index: -1;
  display: block;
  content: "";
  position: absolute;
  width: 10px;
  left: 100%;
  top: 0;
  height: 100%;
}
.aside-left__nav__item__icon {
  border-radius: 5px;
  transition: opacity 300ms, background-color 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  width: 50px;
  height: 60px;
  cursor: pointer;
}
.aside-left__nav__item__icon i {
  font-size: 20px;
}
.aside-left__nav__item__icon:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}
.aside-left__nav__item__title {
  font-size: 10px;
  margin-top: 6px;
}
.aside-left__nav__item__hint {
  position: absolute;
  left: 100%;
  margin-left: 25px;
  padding: 15px;
  line-height: 20px;
  top: 0;
  white-space: nowrap;
  height: 50px;
  display: block;
  background-color: var(--color-black);
  transform: scale(0.001, 0.001);
  opacity: 0;
  transition: transform 200ms, opacity 100ms;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.17);
  border-radius: 6px;
}
.aside-left__nav__item__hint::before {
  content: "";
  display: block;
  border-top: 8px solid transparent;
  border-right: 8px solid var(--color-black);
  border-bottom: 8px solid transparent;
  position: absolute;
  right: 100%;
  top: 18px;
}
.aside-left__nav__item:hover > .aside-left__nav__item__hint {
  transform: scale(1, 1);
  opacity: 1;
}
.aside-left__nav__item:hover > .aside-left__nav__item__foldout, .aside-left__nav__item:focus-within > .aside-left__nav__item__foldout {
  width: 240px;
  opacity: 1;
}
.aside-left__nav__item__foldout {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  opacity: 0;
  transition: width 200ms, opacity 200ms;
  position: absolute;
  left: 100%;
  transform: translateY(-50%);
  top: 50%;
  margin-left: 10px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.17);
}
.aside-left__nav__item__foldout a {
  opacity: 0.5;
  transition: opacity 200ms;
}
.aside-left__nav__item__foldout a:hover {
  opacity: 1;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}
.aside-left__nav__item__foldout ul {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  color: var(--color-black);
}
.aside-left__nav__item__foldout ul > li {
  padding: 15px 20px;
}
.aside-left__nav__item__foldout ul > li:first-child {
  padding-top: 20px;
}
.aside-left__nav__item__foldout ul > li:last-child {
  padding-bottom: 20px;
}
.aside-left__nav__item__foldout .serviceitem {
  border-left: 6px solid var(--theme-color-cta);
  padding-left: 19px;
}
.aside-left__nav__item__foldout .serviceitem + .portalitem {
  border-top: 1px solid var(--color-grey-lines);
}
.aside-left__nav__item__foldout .portalitem {
  padding-left: 25px;
}