/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-locations {
  position: relative;
  background-color: #fff;
  z-index: 0;
}
.widget-locations .widget-locations--inline {
  border: 1px solid var(--color-grey-lines);
}
.widget-locations__select {
  margin: 0 20px 20px;
  position: relative;
  font-size: inherit;
  height: 40px;
  line-height: 38px;
}
.widget-locations__select select {
  position: relative;
  border: 1px solid var(--color-grey-lines);
  border-radius: 2px;
  background: transparent;
  font-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  height: 38px;
  width: 100%;
  padding: 0 30px 0 10px !important;
  transition: border-color 0.3s;
}
.widget-locations__select select:hover, .widget-locations__select select:focus {
  outline: none;
  border-color: var(--color-black);
}
.widget-locations__select select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.widget-locations__select .arrow {
  width: 38px;
  height: 38px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 11px;
  pointer-events: none;
  color: #cbcbcb;
  transition: color 0.3s;
}
.widget-locations__select select:hover + .arrow, .widget-locations__select select:focus + .arrow {
  color: var(--color-black);
}
.widget-locations__container {
  overflow: hidden;
  max-width: 100%;
}
.widget-locations__container__inner {
  display: flex;
  align-items: flex-start;
  transition: transform 0.3s;
  transform: translateX(0);
}
.widget-locations__location {
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  flex: none;
  width: 100%;
  position: relative;
}
.widget-locations__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-locations__line {
  padding: 15px 20px;
  display: block;
  text-decoration: none;
  position: relative;
}
.widget-locations__line.hoverbarfx::before {
  left: 0;
  max-width: calc(100% - 40px);
}
.widget-locations__line + .widget-locations__line::after, div + .widget-locations__line::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  border-top: 1px solid var(--color-grey-lines);
}
.widget-locations__line .icon {
  font-size: 24px;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 30px;
  color: #aaacae;
  margin-top: -10px;
  transition: color 0.3s;
}
.widget-locations__line.hoverbarfx:hover .icon {
  color: inherit;
}
.widget-locations__line--title {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}
.widget-locations__line--title .icon {
  margin-right: -15px;
  margin-top: -12px;
}