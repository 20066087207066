/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-topsubjects ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  border-top: 1px solid var(--color-grey-lines);
}
.widget-topsubjects h3 {
  font-size: 20px;
}
.widget-topsubjects li {
  border-bottom: 1px solid var(--color-grey-lines);
}
.widget-topsubjects a {
  text-decoration: none;
  display: block;
  position: relative;
  padding: 15px 45px 15px 0;
  font-weight: 300;
  font-size: 17px;
}
.widget-topsubjects a:hover .title {
  text-shadow: 0 0 1px var(--color-black);
}
.widget-topsubjects a:hover .arrow {
  opacity: 1;
}
.widget-topsubjects .arrow {
  opacity: 0.5;
  transition: opacity 0.2s;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
}