/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
#browzineWidget {
  margin-bottom: 30px;
}

/* Some general widgets styling for elements used in multiple widgets */
html.wh-widgetpreview {
  background-color: #f0f1f2;
}
html.wh-widgetpreview .widget {
  background-color: #fff;
  max-width: 320px;
  margin: 20px auto;
}

.embeddedobject, .widget {
  margin-top: 30px;
  margin-bottom: 30px;
}

.widget__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 25px 20px;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 1604px) {
  .widget__title {
    font-size: 1.246882793vw;
  }
}
@media (max-width: 1443.6px) {
  .widget__title {
    font-size: 18px;
  }
}
.widget__title + .widget {
  margin-top: 0;
}
.widget__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget__header__button {
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
}
.widget__header__button:hover {
  text-decoration: underline;
}
.widget__contentlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 15px 0 0;
}
.widget__contentlist a:hover .title {
  text-shadow: 0 0 1px var(--color-black);
}
.widget__contentlist__item a {
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 10px 0;
  font-size: 14px;
  line-height: 160%;
  position: relative;
}
.widget__contentlist__item--seen a {
  opacity: 0.5;
  transition: opacity 300ms;
}
.widget__contentlist__item--seen a:hover {
  opacity: 1;
}
.widget__contentlist__item .title {
  font-size: 17px;
}
.widget__contentlist__item .date {
  padding-top: 7px;
  font-size: 13px;
  color: var(--color-grey-normal);
}
.widget__contentlist__item .description {
  padding-top: 5px;
  color: var(--color-dark-grey);
}
.widget__contentlist__item__image {
  display: block;
  flex: none;
  width: 22%;
  min-height: 100px;
  margin-right: 30px;
  position: relative;
  padding-top: 5px;
}
@media (max-width: 830px) {
  .widget__contentlist__item__image {
    margin-right: 20px;
  }
}
.widget__contentlist__item__image > img {
  width: 100%;
  height: auto;
}
.widget__contentlist__item__details {
  display: block;
}
.widget__contentlist__item__details > span {
  display: block;
}

/* Below copied from ws2016 widgets.scss*/
.widget__title--belowphotos {
  padding: 20px;
}
.widget--embedded .widget__title {
  padding-left: 0;
  padding-right: 0;
}

.main__colright .widget__title {
  padding-left: 0;
  padding-right: 0;
}

.widget__title--withicon {
  padding-right: 64px;
}

.widget__title-icon {
  color: #aaacae;
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: inherit;
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  width: 24px;
}

.widget__title-subtitle {
  display: block;
  font-weight: 300;
  margin-top: 2px;
}
.widget__title--belowphotos .widget__title-subtitle {
  text-transform: none;
}

.widget__text {
  color: #1e2328;
  padding: 20px;
}
.widget__title + .widget__text {
  padding-top: 0;
}

.widget__list {
  overflow: hidden;
}
.widget__infoslide--contact .widget__list {
  margin-top: 10px;
}

.widget__listitem {
  border-color: #dcddde;
  border-style: solid;
  border-width: 1px 0 0 0;
  color: #333333;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin: 0 20px;
  position: relative;
  z-index: 0;
}
html[dir=ltr] .widget__listitem:not(.overflow) {
  padding: 15px 40px 15px 0;
}
.widget__listitem--noborder {
  border-width: 0;
}
.widget--embedded .widget__listitem {
  margin: 0;
}
.widget--embedded .widget__listitem:last-child {
  border-bottom-width: 1px;
}
.widget__photos + .widget__list .widget__listitem:first-child {
  border-top-width: 0;
}
.widget__list:not(:last-child) .widget__listitem:last-child {
  border-bottom-width: 1px;
}
.widget__infoslide--contact .widget__listitem {
  padding-bottom: 10px;
  padding-top: 10px;
}
.widget__listitem--link {
  display: block;
  text-decoration: none;
}
.widget__listitem--link:before {
  transition: transform 150ms;
  background: #f7f8f8;
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  z-index: -2;
}
html[dir=ltr] .widget__listitem--link:before {
  left: -20px;
  right: 20px;
  transform: translateX(-100%);
}
html[dir=ltr] .widget__listitem--link:hover:before {
  transform: translateX(0);
}

.widget__listitem-supertext {
  color: #1e2328;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  white-space: pre;
}

.widget__listitem-subtext {
  color: #1e2328;
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre;
}

.widget__listitem-icon {
  color: #aaacae;
  display: block;
  font-size: 24px;
  font-weight: 400;
  height: 24px;
  position: absolute;
  text-align: center;
  top: calc(50% - 12px);
  transition: color 150ms;
  width: 24px;
  /* If the list isn't the last widget component, the last item has a bottom border */
}
.widget__listitem-icon--small, .widget__listitem-icon--invisible {
  font-size: 16px;
  height: 16px;
  top: calc(50% - 8px);
}
.widget__listitem-icon--invisible {
  color: transparent;
}
.widget__listitem--link:hover .widget__listitem-icon {
  color: #1e2328;
}
html[dir=ltr] .widget__listitem-icon {
  right: 8px;
}

.widget__listitem.overflow {
  line-height: 50px;
  max-height: 50px;
  padding: 0;
  position: relative;
  z-index: 0;
  width: calc(100% - 40px);
}
.widget__listitem.overflow .overflow__container {
  overflow: hidden;
  max-height: 50px;
}
.widget__listitem.overflow .softbreak {
  display: block;
  float: left;
  margin-right: -1px;
  width: 1px;
}
.widget__listitem.overflow .widget__listitem-icon {
  float: right;
  line-height: 50px;
}
.widget__listitem.overflow .widget__listitem-text {
  float: left;
  min-width: calc(100% - 40px);
  position: relative;
  white-space: nowrap;
}
.widget__listitem.overflow .widget__listitem-text:before {
  content: attr(data-overflow);
  left: 0;
  margin-top: -50px;
  position: absolute;
  top: 0;
}
.widget--embedded .widget__listitem.overflow {
  width: 100%;
}
.widget__listitem.overflow .widget__listitem-text {
  min-width: calc(100% - 40px);
}
.widget--embedded .widget__listitem.overflow .widget__listitem-text {
  min-width: 100%;
}
.widget__listitem.overflow .widget__listitem-icon {
  display: inline;
  height: auto;
  margin-left: 8px;
  margin-right: 8px;
  position: static;
  vertical-align: top;
}

.widget__infos-listheader {
  font-weight: 700;
  text-transform: uppercase;
}
.widget__infos-listheader:not(:first-child) {
  margin-top: 10px;
}

.widget__infos-listicon {
  color: #aaacae;
  left: 20px;
  margin-top: 3px;
  position: absolute;
}

.widget__infos-listlink {
  color: inherit;
  display: block;
  text-decoration: underline;
}
.widget__infos-listlink:not(:first-child) {
  margin-top: 10px;
}

.widget__link {
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin: 0 20px;
  padding: 15px 0;
}
.widget__title + .widget__link {
  padding-top: 0;
}

.widget__link + .widget__link {
  padding-top: 10px;
  margin-top: -10px;
}

.widget__iconlinks {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.widget__iconlink {
  color: #aaacae;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  font-size: 24px;
  height: 24px;
  margin: 15px 10px;
  text-decoration: none;
  width: 24px;
}
.widget__iconlink:hover {
  color: #1e2328;
}