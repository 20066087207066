/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.librarypage .universe {
  background-size: cover;
}
.librarypage .universe::after {
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.librarypage .universe__container {
  display: none;
}
.librarypage .header-content__topbar {
  background-color: #f6f7f8;
}
@media (min-width: 901px) {
  .librarypage .header-content__pagetitle {
    position: relative;
    top: 100px;
  }
}
.librarypage .header__itemlist-itemblocks {
  position: relative;
  top: -20px;
}
.librarypage .main__contents {
  z-index: 1;
}

.header__librarytabs {
  --tab-height: 60px;
  border-bottom: 1px solid #e0e0e1;
  margin-bottom: calc(0px - var(--tab-height));
  position: relative;
  top: calc(0px - var(--tab-height) - var(--main-padding));
  z-index: 2;
}
@media (max-width: 1024px) {
  .header__librarytabs {
    border-bottom: none;
    margin-bottom: calc(-15px - var(--tab-height));
  }
}
.header__librarytabs .widget-findut__form {
  border: 1px #e1e1e2 solid;
  margin-bottom: 0;
}
.header__librarytabs .widget-findut__form input:focus {
  outline: none;
}

.header__librarytabs-tabs {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  font-size: 14.4px;
  font-weight: var(--weight-bold-condensed);
  height: var(--tab-height);
  justify-content: flex-start;
  overflow: hidden;
  text-transform: uppercase;
}
.header__librarytabs-tab {
  align-items: center;
  background: #ffffff;
  border-top: 3px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 24px;
}
@media (max-width: 1024px) {
  .header__librarytabs-tab:first-child {
    margin-left: 30px;
  }
  .header__librarytabs-tab:last-child {
    margin-right: 30px;
  }
}
@media (max-width: 830px) {
  .header__librarytabs-tab {
    padding: 0px 16px;
  }
  .header__librarytabs-tab:first-child {
    margin-left: 20px;
  }
  .header__librarytabs-tab:last-child {
    margin-right: 20px;
  }
}
.header__librarytabs-tab:not(:first-child) {
  border-left: 1px solid #e0e0e1;
}
.header__librarytabs-tab--active {
  border-top-color: var(--theme-color);
}

.header__librarytabs-page {
  display: none;
  padding: 30px 0;
}
@media (max-width: 1024px) {
  .header__librarytabs-page {
    padding: 30px;
  }
}
@media (max-width: 830px) {
  .header__librarytabs-page {
    padding: 20px;
  }
}
.header__librarytabs-page--active {
  display: block;
}

.header__librarytabs-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
@media (max-width: 600px) {
  .header__librarytabs-links {
    grid-template-columns: 1fr 1fr;
  }
}

.header__librarytabs-link {
  border: 1px solid var(--color-grey-lines);
  border-radius: 4px;
  color: var(--color-grey-dark);
  margin: 1px;
  overflow: hidden;
}
.header__librarytabs-link:hover {
  border-color: var(--theme-color-cta);
  border-width: 2px;
  color: var(--color-black);
  margin: 0;
}
.header__librarytabs-link a {
  display: block;
  overflow: hidden;
  padding: 18px 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-socials__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.widget-socials__item:not(:first-child) {
  margin-left: 16px;
}
.widget-socials__item .UT-icon {
  color: #ffffff;
}
.widget-socials__item a {
  background-color: var(--theme-color-cta);
  border-radius: 100%;
  display: block;
  font-size: 24px;
  height: 44px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  width: 44px;
}
.widget-socials__item a:hover {
  background-color: var(--theme-color-cta-hover);
}