/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.topics-startpage__topics > .widget {
  margin-bottom: 0;
  margin-top: 0;
}
.topics-startpage__topics ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.topics-startpage__topics .widget-trendingtopics__item {
  flex: 1 1 50%;
  max-width: calc(50% - 35px);
  margin-top: 0;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
}
@media (max-width: 1604px) {
  .topics-startpage__topics .widget-trendingtopics__item {
    max-width: calc(50% - 2.2vw);
  }
}
@media (max-width: 830px) {
  .topics-startpage__topics ul {
    display: block;
  }
  .topics-startpage__topics .widget-trendingtopics__item {
    max-width: none;
  }
}

.topicdetails-header .topicdetails-header__text {
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.topicdetails-header .topicdetails-header__text h1 {
  margin: 0 auto;
  width: 100%;
  max-width: 735px;
}
.topicdetails-header .topicdetails-header__image::before {
  content: "";
  display: block;
  padding-top: 66.6666%;
}

.topicdetails__updates__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 23px;
}
@media (max-width: 1604px) {
  .topicdetails__updates__title {
    font-size: 1.433915212vw;
  }
}
@media (max-width: 1394.7826086957px) {
  .topicdetails__updates__title {
    font-size: 20px;
  }
}
.topicdetails__updates ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
.topicdetails__updates__item {
  display: flex;
  position: relative;
  border-left: 1px solid var(--color-grey-lines);
}
.topicdetails__updates__item .date {
  border-top: 1px solid var(--color-grey-lines);
  flex: none;
  width: 150px;
  color: #ABACAE;
  font-size: 13px;
  padding: 10px 15px;
  margin-right: 40px;
}
.topicdetails__updates__item a {
  display: block;
  position: relative;
  flex: 1 1 100%;
  margin-top: -15px;
  line-height: 160%;
  text-decoration: none;
  padding: 5px 0;
  margin-bottom: 20px;
  max-width: 100%;
  overflow: hidden;
}
@media (max-width: 550px) {
  .topicdetails__updates__item {
    flex-flow: column;
  }
  .topicdetails__updates__item .date {
    margin-right: 0;
  }
  .topicdetails__updates__item a {
    margin: 3px 0 15px 15px;
  }
}
.topicdetails__updates__item .title {
  font-size: 17px;
  padding-bottom: 10px;
  display: block;
}
.topicdetails__updates__item .description {
  display: block;
  color: var(--color-grey-dark);
}
.topicdetails__updates__item .description > *:last-child {
  margin-bottom: 0;
}
.topicdetails__updates__item + .topicdetails__updates__item::before {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-lines);
  background-color: #f0f0f0;
}