/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
html.cursor-grabbing {
  cursor: grabbing;
}

/* fav manager overlay */
.rightoverlaypanel {
  --bookmarks-show-duration: 350ms;
  position: fixed;
  right: 0;
  top: 78px;
  bottom: 0;
  z-index: 4;
  width: 0;
  max-width: 100vw;
}
@media (max-width: 830px) {
  .rightoverlaypanel {
    top: 60px;
  }
}
html.showfavoritesoverlay .rightoverlaypanel {
  width: 300px;
}
.rightoverlaypanel a[href="x-serviceportal:login"] {
  color: var(--theme-color-cta);
}
.rightoverlaypanel__container {
  padding: 40px 30px;
  width: 300px;
  max-width: 100vw;
  background-color: var(--color-white);
  border-left: 1px solid var(--color-grey-lines);
  height: 100%;
}
.rightoverlaypanel__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 15px;
}
.rightoverlaypanel__header h3 {
  flex: 1 1 auto;
  white-space: nowrap;
}
.rightoverlaypanel__header .button {
  flex: none;
}
.rightoverlaypanel__tip {
  display: none;
  align-items: flex-start;
  border-left: 5px solid var(--theme-color-cta);
  border-radius: 4px;
  padding: 20px;
  background-color: var(--color-grey-light);
}
html.loggedin .rightoverlaypanel__tip {
  display: flex;
}
.rightoverlaypanel__tip__icon {
  color: var(--theme-color-cta);
  flex: none;
  padding-right: 20px;
  font-size: 18px;
}
.rightoverlaypanel__tip__text {
  flex: 1 1 auto;
  line-height: 160%;
  color: var(--color-grey-dark);
}
.rightoverlaypanel__tip__text b {
  display: inline-block;
  padding-bottom: 5px;
  color: var(--color-black);
  font-weight: var(--weight-normal);
}
.mode-manager .rightoverlaypanel__tip {
  display: none !important;
}
.rightoverlaypanel .favorites-managebtn::before {
  content: "Manage";
}
.rightoverlaypanel__header .button {
  margin-top: -6px;
}
.rightoverlaypanel.mode-manager .favorites-managebtn {
  background-color: var(--color-black);
  color: #fff;
  border-color: var(--color-black);
}
.rightoverlaypanel.mode-manager .favorites-managebtn::before {
  content: "Done";
}

.rightoverlaypanel--favorites h3,
.rightoverlaypanel--favorites .wh-styledinput h3 {
  font-size: 20px;
}
.rightoverlaypanel--favorites .favorites-managebtn {
  display: none;
}
.rightoverlaypanel--favorites.hasbookmarks .favorites-managebtn {
  display: block;
}
.rightoverlaypanel--favorites .button[data-action=addcategory] {
  margin-top: 10px;
  display: none;
}
.rightoverlaypanel--favorites.mode-manager .button[data-action=addcategory] {
  display: inline-flex;
}
.rightoverlaypanel--favorites.mode-manager .button.favorites-addcustombtn {
  display: none;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category:not(.bookmarks__category--fixed) .bookmarks__category__title[data-title] {
  cursor: pointer;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category:not(.bookmarks__category--fixed) .bookmarks__category__title[data-title]::after {
  display: inline-block;
}
.rightoverlaypanel--favorites.mode-manager .aboveitem {
  border-bottom: 1px solid var(--color-dogwood-rose);
}
.rightoverlaypanel--favorites.mode-manager .aboveitem::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 7px;
  border-left: 1px solid var(--color-dogwood-rose);
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__list__item {
  color: var(--color-black);
  z-index: 1;
  position: relative;
  transition: opacity 0.3s;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__list__item .icon {
  opacity: 1;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__list__item a:hover {
  text-decoration: none;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__list__item.dragged {
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 100;
  padding: 10px;
  pointer-events: none;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__list__item.fadeout > * {
  opacity: 0.2;
}
html:not(.cursor-grabbing) .rightoverlaypanel--favorites.mode-manager .bookmarks__category:not(.bookmarks__category--fixed) .bookmarks__list__item {
  cursor: grab;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category:not(.bookmarks__category--fixed) .bookmarks__list__item:hover {
  outline: 1px dotted var(--color-grey-dark);
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category:not(.bookmarks__category--fixed) .delete {
  display: inline-block;
  visibility: visible;
  z-index: 0;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category--fixed .bookmarks__list__item:hover {
  color: inherit;
}
.rightoverlaypanel--favorites.mode-manager .bookmarks__category--fixed .bookmarks__list__item a:hover {
  cursor: default;
}
.rightoverlaypanel--favorites.loadingbookmarks .rightoverlaypanel__content::after {
  content: "Loading...";
  display: block;
}
.rightoverlaypanel--favorites .bookmarks__category {
  margin-bottom: 30px;
  position: relative;
}
.rightoverlaypanel--favorites .bookmarks__category__title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  position: relative;
  padding-left: 5px;
  margin-left: -5px;
  position: relative;
  padding-right: 20px;
}
.rightoverlaypanel--favorites .bookmarks__category__title :not(:is(h3)) {
  color: var(--color-grey-dark);
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--weight-normal);
}
.rightoverlaypanel--favorites .bookmarks__category:not(.rightoverlaypanel--favorites .bookmarks__category--fixed) .rightoverlaypanel--favorites .bookmarks__category__title[data-title]::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e97f"; /* UT-icon-UT_icons_165_filter-16px */
  font-size: 16px;
  display: none;
  position: absolute;
  right: 0;
  top: 1px;
}
.rightoverlaypanel--favorites .bookmarks__category > input {
  width: 100%;
}
.rightoverlaypanel--favorites .bookmarks__category > input + .bookmarks__category__title {
  display: none;
}
.rightoverlaypanel--favorites .bookmarks__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 0 -5px;
}
.rightoverlaypanel--favorites .bookmarks__list li {
  color: var(--color-grey-normal);
  padding: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  border-bottom: 1px solid transparent;
  transition: color 0.2s;
}
.rightoverlaypanel--favorites .bookmarks__list li textarea {
  padding: 3px 5px;
  font-size: 13px;
  line-height: 16px;
  resize: none;
  height: 50px;
  min-height: 50px;
  width: 100%;
}
.rightoverlaypanel--favorites .bookmarks__list li textarea + a {
  display: none;
}
.rightoverlaypanel--favorites .bookmarks__list .icon, .rightoverlaypanel--favorites .bookmarks__list .delete {
  font-size: 16px;
  flex: none;
  width: 16px;
  height: 16px;
}
.rightoverlaypanel--favorites .bookmarks__list .icon {
  color: var(--color-grey-dark);
  margin-right: 15px;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.rightoverlaypanel--favorites .bookmarks__list li:hover {
  color: var(--color-grey-dark);
}
.rightoverlaypanel--favorites .bookmarks__list li:hover .icon {
  opacity: 1;
}
.rightoverlaypanel--favorites .bookmarks__list a {
  flex: 1 1 100%;
  display: block;
  text-decoration: none;
  position: relative;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rightoverlaypanel--favorites .bookmarks__list a:hover {
  text-decoration: underline;
}
.rightoverlaypanel--favorites .bookmarks__category--fixed .icon {
  color: var(--color-tahiti-gold);
}
.rightoverlaypanel--favorites .bookmarks__list .delete,
.rightoverlaypanel--favorites .bookmarks__category .delete {
  margin-left: 20px;
  cursor: pointer;
  transition: opacity 0.3s;
  visibility: hidden;
  z-index: -1;
  border-radius: 50%;
  border: 1px solid var(--color-black);
  transition: background-color 0.3s, color 0.3s;
}
.rightoverlaypanel--favorites .bookmarks__list .delete::before,
.rightoverlaypanel--favorites .bookmarks__category .delete::before {
  font-size: 14px;
  position: relative;
  top: -2px;
  left: 0px;
}
.rightoverlaypanel--favorites .bookmarks__list .delete:hover,
.rightoverlaypanel--favorites .bookmarks__category .delete:hover {
  color: #fff;
  background-color: var(--color-black);
}
.rightoverlaypanel--favorites .bookmarks__category i[data-action=deletecategory] {
  position: absolute;
  right: -30px;
  top: 0;
}

/* wrapper container for bookmark btn */
.bookmarkpage-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: none;
}
@media (max-width: 830px) {
  .bookmarkpage-container {
    display: none;
  }
}

/* bookmark btn */
.bookmarkpage {
  position: relative;
  display: flex;
}
.bookmarkpage__unselect {
  display: none;
}
.bookmarkpage b {
  font-weight: var(--weight-bold);
}
.bookmarkpage--square {
  border-radius: 4px;
  border: 1px solid var(--color-grey-lines);
  color: var(--color-grey-normal);
  width: 40px;
  height: 40px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 200ms, border-color 200ms;
}
@media (max-width: 830px) {
  .bookmarkpage--square {
    width: 32px;
    height: 32px;
  }
}
.bookmarkpage--square .icon--active {
  display: none;
}
.bookmarkpage--square.bookmarkpage--selected, .bookmarkpage--square:hover {
  color: var(--theme-color-cta);
  border-color: var(--color-cta);
}
.bookmarkpage--square.bookmarkpage--selected .icon--inactive, .bookmarkpage--square:hover .icon--inactive {
  display: none;
}
.bookmarkpage--square.bookmarkpage--selected .icon--active, .bookmarkpage--square:hover .icon--active {
  display: block;
}
.bookmarkpage > div {
  cursor: pointer;
  white-space: nowrap;
}
.bookmarkpage > div i {
  flex: none;
  padding-right: 7px;
}
.bookmarkpage > div:hover .label {
  text-underline-position: under;
  text-decoration: underline;
}
.bookmarkpage__select, .bookmarkpage--selected .bookmarkpage__unselect {
  display: flex;
}
.bookmarkpage--selected .bookmarkpage__select {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.bookmarkpage__select.animation, .bookmarkpage.animation {
  position: absolute;
}
.bookmarkpage__select.animation > .label, .bookmarkpage.animation > .label {
  display: none;
}

@media (max-width: 850px) {
  .header-content .bookmarkpage .label {
    display: none;
  }
}