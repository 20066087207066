/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
footer a {
  color: inherit;
  text-decoration: none;
}
footer .footer__container {
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 67px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 830px) {
  footer .footer__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.footer__top {
  color: #fff;
  background-color: var(--color-bondi-blue);
  min-height: 67px;
  padding-top: 5px;
  display: flex;
  position: relative;
}
.footer__top--large {
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer__top--large .footer__container {
  align-items: flex-start;
}
.footer__top .alignmenthelper {
  flex: 1 0 auto;
}
@media (max-width: 830px) {
  .footer__top--large {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer__top .footer__container {
    flex-flow: column;
  }
  .footer__top .footer__container .footer__feedback {
    max-width: 100%;
    padding-right: 0;
  }
  .footer__top .footer__container .feedback__formholder {
    min-width: 0;
  }
}

.footer__bottom {
  border-top: 1px solid var(--color-grey-lines);
  font-size: 13px;
  padding: 5px 0;
}
.footer__bottom a {
  text-decoration: none;
  display: inline-block;
  margin: 5px 0 5px 40px;
}
.footer__bottom a:hover {
  text-decoration: underline;
}
.footer__bottom__copyright {
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.5;
  flex: 1 1 auto;
}
@media (max-width: 830px) {
  .footer__bottom__copyright {
    display: inline-block;
  }
  .footer__bottom .footer__container {
    flex-wrap: wrap;
  }
  .footer__bottom__nav {
    display: inline-block;
    margin-right: -20px;
  }
  .footer__bottom__nav a {
    margin-left: 0;
    margin-right: 20px;
  }
}

.footer__feedback {
  padding: 20px 30px 20px 0;
  max-width: 40%;
  font-size: 15px;
  line-height: 150%;
  position: relative;
}
.footer__feedback__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  padding-bottom: 20px;
}
@media (max-width: 1604px) {
  .footer__feedback__title {
    font-size: 1.9950124688vw;
  }
}
@media (max-width: 1203px) {
  .footer__feedback__title {
    font-size: 24px;
  }
}