/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.header-notification,
.page-notification {
  background-color: #FDF6EE;
  border-left: 4px solid #f0af75;
  padding: 20px;
  line-height: 160%;
  position: relative;
}
.header-notification__title,
.page-notification__title {
  flex: none;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.header-notification__title i,
.page-notification__title i {
  color: #EC7A08;
  display: block;
  flex: none;
  width: 35px;
  font-size: 18px;
}
.header-notification__close,
.page-notification__close {
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #EC7A08;
}
.header-notification__description,
.page-notification__description {
  color: #5A5E62;
}
.header-notification__description > *:last-child,
.page-notification__description > *:last-child {
  margin-bottom: 0;
}

.header-notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 78px;
  max-width: 100%;
  overflow: hidden;
}
.header-notification__description {
  padding-right: 25px;
}
@media (max-width: 830px) {
  .header-notification {
    min-height: 60px;
  }
}
@media (max-width: 600px) {
  .header-notification {
    display: block;
  }
  .header-notification__description {
    padding-top: 5px;
  }
}

.page-notification {
  border-radius: 4px;
  margin-bottom: 20px;
}
.page-notification__description {
  padding-top: 5px;
  padding-left: 35px;
}