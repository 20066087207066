/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-image__container {
  position: relative;
  cursor: pointer;
}
.widget-image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-image__fullscreen {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
}
.widget-image__fullscreen .icon {
  transition: opacity 0.3s;
  opacity: 0.5;
}
.widget-image__fullscreen:hover .icon {
  opacity: 1;
}