/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-widgetblock {
  margin-bottom: 0;
}
.widget-widgetblock > .widget__title {
  margin-bottom: 20px;
}
.widget-widgetblock h3 {
  margin-bottom: 10px;
}
.widget-widgetblock__container {
  margin-left: -30px;
}
.widget-widgetblock__container::after {
  content: "";
  display: block;
  clear: both;
}
.widget-widgetblock__item {
  padding-left: 30px;
  padding-bottom: 30px;
  float: left;
  width: 50%;
  position: relative;
}
.widget-widgetblock__item:empty {
  display: none;
}
.widget-widgetblock__item .widget {
  margin: 0;
  border: 1px solid var(--color-grey-lines);
}
@media (max-width: 600px) {
  .widget-widgetblock__item {
    float: none;
    width: auto;
  }
}