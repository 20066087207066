/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.webappslist {
  margin-top: 50px;
}
.webappslist__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.webappslist__title h2 {
  flex: 1 1 auto;
}
.webappslist__count {
  padding-left: 20px;
  flex: none;
}
.webappslist__form {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #F0F1F2;
  border-radius: 5px;
  height: 68px;
  color: inherit;
  position: relative;
}
.webappslist__form input, .webappslist__form button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 100%;
  border: 0 none;
  border-radius: 0;
  background-color: transparent;
  font-size: 20px;
  display: block;
  color: inherit;
}
.webappslist__form button {
  cursor: pointer;
  font-size: 22px;
  flex: none;
  padding: 0 17px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webappslist__form input {
  height: 100%;
  flex: 1 1 auto;
  font: inherit;
  min-width: 100px;
  padding: 0 15px;
}
.webappslist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.webappslist > .webappslist__title, .webappslist > .webappslist__form, .webappslist > ul {
  max-width: 828px;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
}
.webappslist h2 {
  margin-bottom: 10px;
}
.webappslist__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.webappslist__item[hidden] {
  display: none;
}
.webappslist__item a:hover::before {
  width: calc(100% + 35px);
}
.webappslist__item .bookmarkpage {
  margin: 15px 0 15px 15px;
  flex: none;
}
@media (max-width: 830px) {
  .webappslist__item .bookmarkpage {
    margin: 10px 0;
  }
}
.webappslist__item__description {
  padding-top: 5px;
  color: #5A5E62;
  font-size: 13px;
  opacity: 0.8;
}
.webappslist__item__link {
  display: block;
  flex: 1 1 auto;
  text-decoration: none;
  padding: 15px 15px 15px 0;
}
.webappslist__item__link > span {
  display: block;
  position: relative;
}