/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.header-path {
  position: relative;
  height: 78px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-lines);
}
@media (max-width: 830px) {
  .header-path {
    padding: 0 20px;
  }
}
html.topicspage .header-path {
  border-bottom: 0 none;
}
html.topicspage .header-path::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 0;
}

.header-pagenav {
  max-width: 100%;
  border-top: 1px solid var(--color-grey-lines);
  padding: 0 35px;
}
@media (max-width: 830px) {
  .header-pagenav {
    padding: 0 20px;
  }
}
.header-pagenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1px;
  max-width: 1110px;
}
.header-pagenav li {
  height: 69px;
  display: inline-block;
}
.header-pagenav li + li {
  margin-left: 15px;
}
.header-pagenav a {
  text-decoration: none;
  position: relative;
  display: block;
  line-height: 20px;
  padding: 25px 15px;
}
.header-pagenav a > span {
  display: block;
}
.header-pagenav a .underline {
  position: absolute;
  height: 5px;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 2px;
}
.header-pagenav a:active, .header-pagenav a:hover {
  text-shadow: 0 0 1px rgb(0, 0, 0);
}

.header-content {
  position: relative;
  z-index: 1;
}
.header-content__contents {
  flex: 1 1 auto;
  padding: 0 35px;
}
html.homepage.showingfavorites .header-content__contents {
  transition: transform 350ms;
}
@media (max-width: 1868px) {
  html.homepage.showfavoritesoverlay .header-content__contents {
    transform: translateX(calc((100% - 1110px) / 2 - 300px - 40px));
  }
}
@media (max-width: 1528px) {
  html.homepage.showfavoritesoverlay .header-content__contents {
    transform: none;
  }
}
@media (max-width: 830px) {
  .header-content__contents {
    padding: 0 20px;
  }
}
.header-content--twocol {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.header-content--twocol .header-content__contents {
  flex: 1 1 auto;
}
.header-content__colright {
  flex: none;
  width: 574px;
  max-width: 50%;
}
.header-content__colright > .widget {
  margin: 0;
  height: 100%;
}
@media (max-width: 1024px) {
  .header-content--twocol {
    display: block;
  }
  .header-content__contents h1 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  html.homepage .header-content__contents {
    min-height: 0;
    height: 100%;
  }
  html.homepage .header-content__contents .homeintroblock {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  html.homepage .header-content__contents h1 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header-content__colright {
    display: none;
  }
}
.header-content__topbar {
  border-bottom: 1px solid var(--color-grey-lines);
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 35px;
}
.header-content__pagetitle {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 132px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
}
@media (max-width: 830px) {
  .header-content__pagetitle {
    min-height: 180px;
  }
}
.header-content__pagetitle--wide {
  max-width: 1685px;
}
html.contentpage .header-content__pagetitle {
  max-width: 730px;
}
.header-content--pagenav .header-content__pagetitle {
  min-height: 209px;
}
.header-content--withitems .header-content__pagetitle {
  padding-top: 35px;
  min-height: 0;
}
.header-content__items {
  padding-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
}
.header-content__items > p {
  margin: 20px 0 0;
  font-size: 18px;
  color: var(--color-grey-dark);
}
.header-content__pagetitle--wide + .header-content__items {
  max-width: 1685px;
}

.header-searchform {
  align-self: flex-start;
  flex: 1 1 100%;
  transition: opacity 400ms cubic-bezier(0.645, 0, 0.785, 0.39);
  z-index: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  height: 79px;
  margin-bottom: -78px;
  margin-top: 49px;
  max-width: 920px;
  width: 100%;
}
.header-searchform--disabled {
  opacity: 0.5;
  transition: opacity 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.header-searchform--disabled::after {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.header-searchform .title {
  font-size: 22px;
  height: 42px;
  text-transform: uppercase;
  font-weight: bold;
}
.header-searchform .searchbox {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-searchform .searchbox .searchicon {
  font-size: 24px;
  flex: none;
  padding: 10px 25px;
}
.header-searchform .searchbox input {
  outline: none;
  width: 100%;
  padding: 0;
  margin-right: 15px;
  background-color: transparent;
  border: 0 none;
  font-size: inherit;
  display: block;
  height: 26px;
  color: var(--color-black);
  flex: 1 1 auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.header-searchform .searchbox button {
  cursor: pointer;
  align-self: stretch;
  flex: none;
  border: 0 none;
  border-radius: 0;
  color: #fff;
  font-size: 24px;
  padding-top: 2px;
  width: 79px;
  display: block;
  transition: opacity 0.2s;
}
.header-searchform .searchbox button:hover {
  opacity: 0.8;
}
.header-searchform a {
  color: inherit;
}
@media (max-width: 1024px) {
  .header-searchform {
    height: 60px;
    margin-bottom: -29px;
  }
  .header-searchform .searchbox button {
    width: 60px;
  }
}
.header-searchform .suggestions {
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  display: none;
  left: 0;
  padding: 15px;
  min-width: 100%;
  position: absolute;
  top: 80px;
  white-space: nowrap;
  z-index: 10000;
}
.header-searchform .suggestions--show {
  display: block;
}

.homeintroblock {
  z-index: 10;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1110px;
  padding: 37px 0 40px;
}
.homeintroblock__person {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeintroblock h1 {
  padding-left: 20px;
  flex: 1 1 auto;
  text-transform: none;
}
.homeintroblock .roundbutton {
  flex: none;
  width: 78px;
  height: 78px;
  box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  display: inline-flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-size: cover;
}
.homeintroblock .roundbutton--login {
  cursor: pointer;
}
.homeintroblock .roundbutton .placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
@media (max-width: 1024px) {
  .homeintroblock .roundbutton {
    width: 60px;
    height: 60px;
  }
}
.homeintroblock__roles {
  margin-top: 20px;
  font-size: 15px;
  line-height: 22px;
  position: relative;
  z-index: 1;
}
.homeintroblock__roles__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeintroblock__roles--withpulldown .homeintroblock__roles__selected {
  cursor: pointer;
}
.homeintroblock__roles--withpulldown .homeintroblock__roles__selected::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92d";
  font-size: 12px;
  margin-right: 5px;
  opacity: 0.6;
  line-height: 22px;
}
.homeintroblock__roles__pulldown {
  overflow: hidden;
  max-height: 0;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  left: 0;
  transition: max-height 300ms;
  max-width: calc(100vw - 40px);
}
.homeintroblock__roles:focus-within .homeintroblock__roles__pulldown {
  max-height: 100vh;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}
.homeintroblock__roles .tag {
  flex: none;
  min-width: 40px;
}
.homeintroblock__roles .tag > span {
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  display: inline-block;
  height: 22px;
  min-width: 35px;
  text-align: center;
  padding: 0 5px;
  font-size: 12px;
}
.homeintroblock__roles .title {
  margin-left: 7px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.homeintroblock__roles ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  padding: 10px 15px 20px 18px;
}
.homeintroblock__roles li {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeintroblock__roles li[hidden] {
  display: none;
}
.homeintroblock__roles li:hover .title, .homeintroblock__roles li:focus .title {
  text-decoration: underline;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}
.homeintroblock__roles li + li {
  margin-top: 10px;
}

.header__itemlist-itemblocks {
  display: flex;
}
.header__itemlist-itemblocks .header__itemlist,
.header__itemlist-itemblocks .header__itemempty {
  min-width: 50%;
}
@media (max-width: 900px) {
  .header__itemlist-itemblocks {
    flex-flow: column;
  }
}

.header__itemblocks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 50%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}
.header__itemblocks + .header__itemblocks {
  margin-left: 0;
}
@media (max-width: 900px) {
  .header__itemblocks + .header__itemblocks {
    margin-left: -15px;
  }
}
.header__itemblocks li {
  flex: 0 1 100%;
  padding-left: 15px;
  margin-top: 15px;
  max-width: 50%;
  min-height: 180px;
}
.header__itemblocks a {
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
  padding: 50px 30px 20px;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  min-height: 100%;
  text-transform: uppercase;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  text-align: center;
  transition: box-shadow 0.3s;
}
.header__itemblocks a:hover {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.3);
}
.header__itemblocks a > * {
  display: block;
}
.header__itemblocks a .title {
  flex: none;
  position: relative;
}
.header__itemblocks a .title > span {
  display: block;
  transition: opacity 0.3s;
}
.header__itemblocks a .title .hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.01;
}
.header__itemblocks a:hover .title > span:first-child {
  opacity: 0;
}
.header__itemblocks a:hover .title > .hover {
  opacity: 1;
}
.header__itemblocks a .icon {
  flex: 1 1 100%;
  font-size: 48px;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .header__itemblocks + .header__itemblocks {
    margin-top: 0;
  }
  .header__itemblocks li {
    max-width: none;
    min-height: 0;
  }
  .header__itemblocks a {
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 15px;
  }
  .header__itemblocks a .title {
    text-align: left;
    max-width: calc(100% - 50px);
  }
  .header__itemblocks a .icon {
    font-size: 24px;
    flex: none;
    width: 45px;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.header__itemlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
.header__itemlist li + li {
  padding-top: 20px;
}
.header__itemlist a {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.header__itemlist a .arrow {
  position: absolute;
  top: 1px;
  left: 0;
  color: #AAACAE;
}
.header__itemlist a > span {
  display: block;
  position: relative;
  padding-left: 45px;
  transition: opacity 0.3s;
}
.header__itemlist a > span:first-child {
  position: absolute;
  left: 0;
  top: 0;
}
.header__itemlist a > span + span {
  opacity: 0.001;
  text-shadow: 0 0 1px rgb(0, 0, 0);
}
.header__itemlist a:hover > span {
  opacity: 0;
}
.header__itemlist a:hover > span + span {
  opacity: 1;
}
.header__itemlist a:hover > span + span .arrow {
  color: inherit;
}