/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.header__mobilenav {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  width: 0;
  bottom: 0;
  height: auto;
  transition: width 0.3s;
  background-color: #fff;
  font-size: 16px;
  overflow: hidden;
  flex-flow: column;
}
.header__mobilenav__scrollcontainer {
  position: relative;
  top: 0;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
}
.header__mobilenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100vw;
}
.header__mobilenav__scrollcontainer > ul {
  position: relative;
  transition: transform 0.3s;
}
.header__mobilenav li > ul {
  position: absolute;
  left: 100%;
  top: 0;
}
.header__mobilenav a {
  text-decoration: none;
}
.header__mobilenav .hoverbarfx {
  position: relative;
}
.header__mobilenav li.loading {
  padding: 20px 30px;
  min-height: 65px;
}
.header__mobilenav .header__mobilenav__item {
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  border-bottom: 1px solid var(--color-grey-lines);
  cursor: pointer;
}
.header__mobilenav .header__mobilenav__item.serviceitem {
  border-left: 6px solid var(--theme-color-cta);
  padding-left: 14px;
}
.header__mobilenav .header__mobilenav__item.hoverbarfx::before {
  left: -25px;
}
.header__mobilenav .header__mobilenav__item .icon {
  text-align: center;
  flex: none;
  width: 30px;
  font-size: 16px;
}
.header__mobilenav .header__mobilenav__item .title + .icon {
  opacity: 0.6;
}

.mobilenav__footer {
  flex: none;
}
.mobilenav__footer__utwente {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  text-decoration: none;
}
.mobilenav__footer__utwente .icon {
  flex: none;
  width: 55px;
}

@media (max-width: 830px) {
  .header__mobilenav {
    display: flex;
  }
  html.showmobilemenu .header__mobilenav {
    width: 100vw;
  }
  html.showmobilemenu .header-notification {
    display: none;
  }
  .header__mobilenav:focus-within {
    width: 100vw;
  }
}