/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.breadcrumbs-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  padding-right: 20px;
  height: 100%;
  padding-top: 27px;
}
.breadcrumbs-container .breadcrumblist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: Arial;
  line-height: 150%;
  color: var(--color-grey-dark);
}
.breadcrumbs-container .breadcrumblist--shorten li:first-child::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e93f...";
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 0 0 0 10px;
}
.breadcrumbs-container .breadcrumblist li {
  display: inline;
}
.breadcrumbs-container .breadcrumblist li.hide {
  display: none;
}
.breadcrumbs-container .breadcrumblist li + li::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 2px;
  font-size: 16px;
  content: "\e93f";
  display: inline-block;
  padding: 0 10px;
}
.breadcrumbs-container .breadcrumblist a {
  text-decoration: none;
  text-underline-position: under;
}
.breadcrumbs-container .breadcrumblist a:hover {
  text-decoration: underline;
}
.breadcrumbs-container .breadcrumblist--mobile {
  padding-right: 20px;
  cursor: pointer;
}
.breadcrumbs-container .breadcrumblist--mobile::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92f";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 3px;
}
.breadcrumbs-container .breadcrumblist--mobile li:first-child::before {
  content: "...";
}
.breadcrumbs-container .breadcrumblist--mobile li:first-child a {
  display: none;
}
.breadcrumbs-container .breadcrumblist--mobile.showall {
  background-color: #fff;
  padding: 10px 0;
  cursor: default;
}
.breadcrumbs-container .breadcrumblist--mobile.showall li {
  color: var(--color-black);
}
.breadcrumbs-container .breadcrumblist--mobile.showall li::before {
  display: none !important;
}
.breadcrumbs-container .breadcrumblist--mobile.showall li:first-child a {
  display: block;
}
.breadcrumbs-container .breadcrumblist--mobile.showall li > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 20px;
}