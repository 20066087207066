@import "@mod-utwente_design/scss/config-colors.css";
@import "/.utdesign/fonts/ut-iconfont.css";
@import "/.utdesign/fonts/ut-iconfont-48px.css";
@import "/.utdesign/fonts/univers-next.css";
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.themecolor--br {
  border-color: var(--theme-color-cta);
}

.themecolor--bg, .wh-form__button.themecolor--bg {
  background-color: var(--theme-color-cta);
}

.themecolor--fg {
  color: var(--theme-color-cta);
}

.themecolor--fill {
  color: var(--theme-color-cta);
}

.bg-lightgrey {
  background-color: var(--color-grey-light);
}

.bg-cashmere {
  background-color: var(--color-cashmere);
}

.bg-dogwood-rose {
  background-color: var(--color-dogwood-rose);
}

.bg-windsor {
  background-color: var(--color-windsor);
}

.bg-cool-black {
  background-color: var(--color-cool-black);
}

.bg-bondi-blue {
  background-color: var(--color-bondi-blue);
}

.bg-malibu-blue {
  background-color: var(--color-malibu-blue);
}

.bg-mosque {
  background-color: var(--color-mosque);
}

.bg-malibu-green {
  background-color: var(--color-malibu-green);
}

.bg-trendy-green {
  background-color: var(--color-trendy-green);
}

.bg-tangerine {
  background-color: var(--color-tangerine);
}

.bg-tahiti-gold {
  background-color: var(--color-tahiti-gold);
}

.bg-crimson-glory {
  background-color: var(--color-crimson-glory);
}

.bg-scarlett {
  background-color: var(--color-scarlett);
}

.bg-matterhorn {
  background-color: var(--color-matterhorn);
}

.bg-dark-grey {
  background-color: var(--color-dark-grey);
}

.fg-cashmere {
  color: var(--color-cashmere);
}

.fg-dogwood-rose {
  color: var(--color-dogwood-rose);
}

.fg-windsor {
  color: var(--color-windsor);
}

.fg-cool-black {
  color: var(--color-cool-black);
}

.fg-bondi-blue {
  color: var(--color-bondi-blue);
}

.fg-malibu-blue {
  color: var(--color-malibu-blue);
}

.fg-mosque {
  color: var(--color-mosque);
}

.fg-malibu-green {
  color: var(--color-malibu-green);
}

.fg-trendy-green {
  color: var(--color-trendy-green);
}

.fg-tangerine {
  color: var(--color-tangerine);
}

.fg-tahiti-gold {
  color: var(--color-tahiti-gold);
}

.fg-crimson-glory {
  color: var(--color-crimson-glory);
}

.fg-scarlett {
  color: var(--color-scarlett);
}

.fg-matterhorn {
  color: var(--color-matterhorn);
}

.fg-dark-grey {
  color: var(--color-dark-grey);
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* loading spinner */
.spinanimation {
  -webkit-animation: spinner-anim 2s infinite linear;
  animation: spinner-anim 2s infinite linear;
}

@-webkit-keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* grey background hover fx, left to right */
.hoverbarfx::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background-color: var(--color-grey-lighter);
  transition: width 300ms, opacity 300ms;
  z-index: -1;
  opacity: 0;
}
.hoverbarfx:hover::before {
  width: 100%;
  opacity: 1;
}

main .hoverbarfx::before {
  left: -20px;
}
main .hoverbarfx:hover::before {
  width: calc(100% + 20px);
}

.centercontent {
  max-width: 1526px;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
}

html {
  background-color: #ffffff;
  min-height: 100%;
}

body {
  min-height: 100vh;
  display: flex;
  overscroll-behavior: none;
}

.wh-anchor {
  position: absolute;
  display: inline-block;
  pointer-events: none;
  visibility: hidden;
}

@media (max-width: 1604px) {
  body h1 {
    font-size: 2.6184538653vw;
  }
}
@media (max-width: 1069.3333333333px) {
  body h1 {
    font-size: 28px;
  }
}

@media (max-width: 1604px) {
  h2 {
    font-size: 1.6209476309vw;
  }
}
@media (max-width: 1357.2307692308px) {
  h2 {
    font-size: 22px;
  }
}

.dialog__modal {
  z-index: 10;
}
.dialog__modal.dialog--small .dialog__body {
  padding-top: 40px;
  overflow: visible;
}
.dialog__modal.dialog--small .dialog__content {
  padding-top: 0;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.rpc__busyoverlay {
  display: none;
}

:selection {
  background-color: #5a5e62;
  color: #ffffff;
}

:-moz-selection {
  background-color: #5a5e62;
  color: #ffffff;
}

::selection {
  background-color: #5a5e62;
  color: #ffffff;
}

*:focus {
  outline: 1px solid #f6f6f6;
}

input:-webkit-autofill {
  background-color: inherit !important;
}

html.homepage article {
  padding-top: 60px;
}

.sitecontent {
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}
.sitecontent header, .sitecontent footer {
  flex: none;
}
.sitecontent main {
  flex: 1 1 auto;
}

.mobile-homepagebanner {
  display: none;
  max-width: none !important;
}
.mobile-homepagebanner > .widget-media {
  padding: 0 !important;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .mobile-homepagebanner {
    display: block;
  }
}

.main-tabs {
  display: none;
  text-transform: uppercase;
  font-size: 18px;
  text-transform: uppercase;
  background-color: var(--color-grey-lines);
  font-weight: 700;
  line-height: 20px;
  margin-top: 15px;
}
html.homepage .main-tabs {
  margin-top: 55px;
}
.main-tabs__tab {
  margin-top: 2px;
  padding: 15px 20px;
  flex: 1 1 50%;
  cursor: pointer;
}
.main-tabs__tab--active {
  background-color: #fff;
}
@media (max-width: 1024px) {
  .main-tabs {
    display: flex;
  }
}

:root {
  --main-padding: 0px;
}

main {
  --main-padding: 65px;
  position: relative;
}
main.main--twocol {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
main.main--twocol > .main__contents {
  flex: 1 1 auto;
}
main.main--twocol > .main__contents > * {
  max-width: 805px;
}
main .main__contents {
  padding-top: var(--main-padding);
  padding-bottom: 60px;
  position: relative;
  z-index: 0;
}
main .main__contents > *:not(.fullwidth), main .main__contents > .fullwidth > *:not(.fullwidth) {
  margin-left: auto;
  margin-right: auto;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 1180px;
}
html.contentpage main .main__contents > *:not(.fullwidth), html.contentpage main .main__contents > .fullwidth > *:not(.fullwidth) {
  max-width: 800px;
}
html.homepage.showingfavorites main .main__contents {
  transition: transform 350ms;
}
@media (max-width: 1868px) {
  html.homepage.showfavoritesoverlay main .main__contents {
    transform: translateX(calc((100% - 1110px) / 2 - 300px - 40px));
  }
}
@media (max-width: 1528px) {
  html.homepage.showfavoritesoverlay main .main__contents {
    transform: none;
  }
}
@media (max-width: 830px) {
  main .main__contents > *:not(.fullwidth), main .main__contents > .fullwidth > *:not(.fullwidth) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1150px;
  }
  html.contentpage main .main__contents > *:not(.fullwidth), html.contentpage main .main__contents > .fullwidth > *:not(.fullwidth) {
    max-width: 770px;
  }
}
main .main__contents > .likebutton {
  margin-left: max((100% - 1110px) / 2, 20px);
}
html.contentpage main .main__contents > .likebutton {
  margin-left: max((100% - 730px) / 2, 20px);
}
main .main__colright {
  background-color: var(--color-grey-lighter);
  flex: none;
  width: 574px;
  max-width: 50%;
  border-left: 1px solid var(--color-grey-lines);
}
main .main__colright > .widget--wide:first-child {
  margin-top: 0;
}
main .main__colright > .widget:not(.widget--wide) {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 55px;
}
@media (max-width: 830px) {
  main .main__colright > .widget:not(.widget--wide) {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 1024px) {
  main .main--twocol {
    display: block;
  }
  main .main__contents {
    background-color: #fff;
    padding-top: 30px !important;
    padding-bottom: 30px;
  }
  main .main__colright {
    max-width: none;
    width: 100%;
    border: 0 none;
    display: none;
    background-color: #fff;
  }
  main .main__colright .widget-media {
    padding: 35px 35px 0;
  }
}
@media (max-width: 1024px) and (max-width: 830px) {
  main .main__colright .widget-media {
    padding: 20px 20px 0;
  }
}
@media (max-width: 1024px) {
  main[data-panel=main__colright] .main__contents {
    display: none;
  }
  main[data-panel=main__colright] .main__colright {
    display: block;
  }
}

.article-topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-grey-normal);
  margin-top: -15px;
  margin-bottom: 30px;
}
.article-topbar__lastupdate {
  color: var(--color-dark-grey);
  line-height: 25px;
}
.article-topbar__lastupdate i {
  display: inline-block;
  color: var(--color-grey-normal);
  position: relative;
  top: 1px;
  padding-right: 5px;
}
.article-topbar .alignmenthelper {
  flex: 1 1 auto;
}
.article-topbar > div {
  flex: none;
}

.article-footerbar {
  color: var(--color-grey-normal);
  margin-bottom: 30px;
}
.article-footerbar__lastupdate {
  color: var(--color-dark-grey);
  line-height: 25px;
}
.article-footerbar__lastupdate i {
  display: inline-block;
  color: var(--color-grey-normal);
  position: relative;
  top: 1px;
  padding-right: 5px;
}

.login-msgbox {
  margin-bottom: 30px;
  position: relative;
}
.login-msgbox a {
  color: var(--color-tahiti-gold);
}
.login-msgbox__content {
  border-radius: 4px;
  background-color: rgba(236, 122, 8, 0.1);
  border: 1px solid rgba(236, 122, 8, 0.2);
  padding: 10px 45px 10px 20px;
  position: relative;
}
.login-msgbox p {
  margin-bottom: 0;
}
.login-msgbox .close {
  position: absolute;
  right: 15px;
  top: 15px;
  color: var(--color-tahiti-gold);
  cursor: pointer;
}

header .login-msgbox {
  margin-top: 30px;
  margin-bottom: 0;
}

.page-msgbox {
  margin-bottom: 30px;
  position: relative;
  visibility: hidden;
}
.page-msgbox--show {
  visibility: visible;
}
.page-msgbox a {
  color: var(--color-bondi-blue);
}
.page-msgbox__content {
  border-left: 6px solid rgba(0, 148, 179, 0.3);
  border-radius: 4px;
  background-color: var(--color-grey-lighter);
  border-top: 1px solid var(--color-grey-light);
  border-right: 1px solid var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey-light);
  padding: 15px 45px;
  position: relative;
}
.page-msgbox p {
  margin-bottom: 0;
}
.page-msgbox__info {
  position: absolute;
  left: 15px;
  top: 19px;
  color: var(--color-bondi-blue);
}
.page-msgbox__close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  color: var(--color-grey-normal);
  cursor: pointer;
}

.pagelanguage {
  white-space: nowrap;
  display: flex;
  line-height: 36px;
}
.pagelanguage b {
  font-weight: 600;
}
.pagelanguage__choice {
  padding: 0 10px;
}
.pagelanguage__choice + .pagelanguage__choice {
  border-left: 1px solid var(--color-grey-lines);
}
.pagelanguage a {
  text-decoration: none;
  text-underline-position: under;
}
.pagelanguage a:hover {
  text-decoration: underline;
}
.pagelanguage b {
  color: var(--color-black);
}
@media (max-width: 600px) {
  .pagelanguage__label {
    display: none;
  }
}

/* vertical perfect scrollbar */
.scrollbar-vertical {
  transform: translateZ(0);
}
.scrollbar-vertical.nativescroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollbar-vertical.ps > .ps__rail-y {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc !important;
  width: 6px;
  border-radius: 3px;
  left: auto !important;
  right: 10px;
}
.scrollbar-vertical.ps > .ps__rail-y > .ps__thumb-y {
  right: 0;
  background-color: #666;
  width: 6px;
  border-radius: 3px;
}
.scrollbar-vertical.ps--active-y .ps__rail-y {
  opacity: 1;
}

html.serviceportal .searchsheet {
  z-index: 6;
}