@charset "UTF-8";
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
html.neo-startpage article, html.neo-contentlistpage article {
  padding: 40px 0 0;
  max-width: none;
}

.neo-detailspage {
  /* specific for event/cultural:
     (showing prices)
  */
}
.neo-detailspage .event__infolist {
  margin-top: 40px;
}
.neo-detailspage .event__infolist h3 {
  position: relative;
  padding-left: 35px;
}
.neo-detailspage .event__infolist h3 .icon {
  font-size: 24px;
  color: var(--color-grey-normal);
  position: absolute;
  top: -3px;
  left: -4px;
}
.neo-detailspage .event__infolist table {
  border-collapse: collapse;
  padding: 0;
  margin: 15px 0;
  width: 100%;
  border-top: 1px solid var(--color-grey-lines);
}
.neo-detailspage .event__infolist table th, .neo-detailspage .event__infolist table td {
  vertical-align: top;
  text-align: left;
  font-size: 18px;
  line-height: 20px;
  font-weight: var(--weight-light-condensed);
  padding: 15px 0;
  border-bottom: 1px solid var(--color-grey-lines);
}
.neo-detailspage .event__infolist table td {
  font-weight: var(--weight-bold-condensed);
  padding-left: 20px;
  width: 160px;
  color: var(--color-grey-dark);
  white-space: nowrap;
}
.neo-detailspage .dialog__modal {
  z-index: 10000;
}
.neo-detailspage #eventregistrationform {
  min-width: 270px;
}
.neo-detailspage__blocksright:empty {
  display: none;
}
.neo-detailspage__header {
  padding-top: 35px;
  margin-bottom: 205px;
}
.neo-detailspage__header__text {
  max-width: 770px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}
.neo-detailspage__header__image {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -205px;
}
.neo-detailspage__header__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (max-width: 730px) {
  .neo-detailspage__header {
    margin-bottom: 28vw;
  }
  .neo-detailspage__header__image {
    margin-bottom: -28vw;
  }
}
.neo-detailspage__header .likebutton {
  margin-bottom: 25px;
  margin-left: max((100% - 730px) / 2, 20px);
}
.neo-detailspage h1 {
  position: relative;
  margin-bottom: 30px;
}
.neo-detailspage .pagenavbar ~ h1 {
  margin-top: -25px;
}
.neo-detailspage__date {
  font-size: 15px;
  padding-bottom: 20px;
  color: var(--color-grey-normal);
}
.neo-detailspage__date .icon {
  color: #aaacae;
  display: inline-block;
  padding-right: 7px;
}
.neo-detailspage__meta {
  margin-bottom: 30px;
  margin-top: 30px;
}
.neo-detailspage__meta__container {
  border-top: 1px solid var(--color-grey-lines);
  padding-top: 10px;
}
.neo-detailspage__meta__row {
  display: flex;
  padding-top: 10px;
  position: relative;
  text-decoration: none;
}
.neo-detailspage__meta__row[href] .row__title {
  text-decoration: underline;
}
.neo-detailspage__meta .addeventatc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.neo-detailspage__meta .row__icon {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #aaacae;
  flex: none;
  width: 20px;
  text-decoration: none;
  margin-right: 10px;
}
.neo-detailspage__meta .row__title {
  display: block;
}
.neo-detailspage__meta .row__title.tags > a {
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 7px 1px;
  line-height: 15px;
  background-color: #fff;
  text-decoration: none;
  margin-right: 4px;
  border-radius: 2px;
  display: inline-block;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  opacity: 0.6;
  transition: opacity 0.3s;
}
.neo-detailspage__meta .row__title.tags > a:hover {
  opacity: 1;
}

.neo-topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-grey-normal);
  margin: 30px auto -15px;
  max-width: 1180px;
  padding: 0 35px;
  width: 100%;
}
@media (max-width: 830px) {
  .neo-topbar {
    padding: 0 20px;
  }
}
.neo-topbar > div {
  flex: none;
}

.neo-startpage__firstevent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 30px 0;
  text-decoration: none;
}
.neo-startpage__firstevent span {
  display: block;
}
.neo-startpage__firstevent__text {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
}
.neo-startpage__firstevent__title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 100%;
  font-weight: var(--weight-bold-condensed);
  margin-bottom: 20px;
}
@media (max-width: 1604px) {
  .neo-startpage__firstevent__title {
    font-size: 1.9950124688vw;
  }
}
@media (max-width: 1203px) {
  .neo-startpage__firstevent__title {
    font-size: 24px;
  }
}
.neo-startpage__firstevent__date {
  font-weight: var(--weight-normal);
  color: var(--color-grey-normal);
  margin-bottom: 20px;
}
.neo-startpage__firstevent__description {
  font-size: 15px;
  line-height: 160%;
}
.neo-startpage__firstevent__image {
  flex: 1 1 66.6666%;
  max-width: 66.6666%;
  padding-left: 10px;
}
.neo-startpage__firstevent__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 600px) {
  .neo-startpage__firstevent {
    flex-flow: column;
  }
  .neo-startpage__firstevent__text {
    max-width: none;
    order: 1;
  }
  .neo-startpage__firstevent__image {
    padding-bottom: 15px;
    max-width: none;
    padding-left: 0;
  }
}
.neo-startpage__block {
  max-width: 100%;
}
.neo-startpage__block html:not(.homepage) {
  padding-bottom: 60px;
}
html.homepage .neo-startpage__block:not(:has(+ .neo-contentlist__header)) {
  margin-bottom: 70px;
  padding-bottom: 70px;
}
html.homepage .neo-startpage__block:not(:has(+ .neo-contentlist__header))::after {
  background-color: #dcdcdc;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin-top: 70px;
  position: absolute;
  right: 0;
}
.neo-startpage__block--event {
  background-color: var(--color-grey-light);
  padding-top: 60px;
  padding-bottom: 60px;
}
.neo-startpage__more {
  text-decoration: none;
  font-weight: var(--weight-bold-condensed);
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  display: block;
  width: 33.3333%;
  margin: 0 auto;
  height: 56px;
  padding: 15px;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color-grey-dark);
  transition: background-color 0.3s;
}
.neo-startpage__more:hover {
  background-color: var(--color-black);
}

.neo-filters {
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 35px;
  width: 100%;
}
@media (max-width: 830px) {
  .neo-filters {
    padding: 0 20px;
  }
}

.neo-filterform-toggle {
  align-items: center;
  border: 1px solid var(--color-grey-lines);
  border-radius: 3px;
  color: var(--color-grey-dark);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: var(--weight-light-condensed);
  gap: 20px;
  height: 39px;
  list-style: none;
  padding: 0 20px;
  transition: background-color 0.3s, color 0.3s;
}
.neo-filterform-toggle::-webkit-details-marker {
  display: none;
}
.neo-filterform[open] .neo-filterform-toggle {
  background: var(--color-grey-dark);
  color: var(--color-white);
}

.neo-filterform-form {
  align-items: stretch;
  border: 1px solid var(--color-grey-lines);
  display: flex;
  height: 39px;
  line-height: 39px;
  margin-top: 20px;
  width: 100%;
}
.neo-filterform-form:has(:focus) {
  border-color: gray;
}
.neo-filterform-form input {
  background: transparent;
  border: none;
  font-size: inherit;
  padding: 0 0 0 20px;
  width: calc(100% - 40px);
}
.neo-filterform-form input:focus {
  outline: none;
}
.neo-filterform-form button {
  background: transparent;
  border: none;
  flex: none;
  text-align: center;
  width: 40px;
}

.neo-tags-desc {
  margin-bottom: 20px;
  margin-top: 20px;
}

.neo-tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
}

.neo-tag {
  background: var(--color-grey-light);
  border-radius: 3px;
  font-size: 13px;
  line-height: 19px;
  min-width: 100px;
  padding: 10px 36px 10px 10px;
  position: relative;
}

.neo-tag-close {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.neo-tag-close i {
  background: var(--color-dark-grey);
  border-radius: 50%;
  color: var(--color-white);
  display: inline-block;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  width: 16px;
}

html:not(.homepage) .neo-contentlist {
  margin-bottom: 30px;
}
.neo-contentlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -30px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .neo-contentlist ul {
    margin-left: -20px;
  }
}

.neo-contentlist__header {
  align-items: center;
  display: flex;
  line-height: 42px;
  margin-top: 40px;
}

.neo-contentlist__title {
  font-size: 22px;
  font-weight: var(--weight-bold-condensed);
  text-transform: uppercase;
  width: 95px;
}

.neo-contentlist__pulldown {
  align-items: center;
  appearance: none;
  background: transparent url(../../web/img/pulldown-arrows.svg);
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 7px 12px;
  border: 1px solid #e6e8ed;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  font-size: 14px;
  height: 46px;
  padding: 0 30px 0 13px;
}

.neo-contentlist__spacer {
  flex: 1;
}

.neo-contentlist__button {
  color: inherit;
  font-size: 18px;
  font-weight: var(--weight-bold-condensed);
  line-height: 42px;
  text-decoration: none;
}
.neo-contentlist__button::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #aaacae;
  content: "\e92b";
  display: inline-block;
  font-size: 10px;
  padding-left: 16px;
  position: relative;
  bottom: 1px;
}
.neo-contentlist__button:hover {
  text-decoration: underline;
}

.neo-contentlist__item {
  margin-top: 30px;
  flex: 1 1 33.3333%;
  padding-left: 30px;
  max-width: 33.3333%;
}
@media (max-width: 850px) {
  .neo-contentlist__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  .neo-contentlist__item {
    margin-top: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 500px) {
  .neo-contentlist__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.neo-contentlist__item a {
  text-decoration: none;
  display: block;
  height: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
}
.neo-contentlist__item a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -15px;
  bottom: -15px;
  left: -15px;
  opacity: 0;
  background-color: var(--color-grey-light);
  transition: opacity 0.3s;
  z-index: -1;
}
.neo-contentlist__item a:hover::before {
  opacity: 1;
}
.neo-contentlist__item--seen a {
  opacity: 0.5;
  transition: opacity 300ms;
}
.neo-contentlist__item--seen a:hover {
  opacity: 1;
}

.neo-contentlist__item__image {
  display: block;
  position: relative;
  margin-bottom: 20px;
}
.neo-contentlist__item__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.neo-contentlist__item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.neo-contentlist__item__text {
  display: grid;
  grid-template-columns: [date title] 1fr;
  grid-template-rows: auto;
}
.neo-contentlist__item__text--event {
  grid-template-columns: [date] minmax(65px, min-content) [title] 1fr;
}

.neo-contentlist__item__date {
  color: var(--color-grey-normal);
  font-size: 13px;
  grid-column: date;
  padding-bottom: 10px;
}

.neo-contentlist__item__eventdate {
  font-size: 18px;
  font-weight: var(--weight-light-condensed);
  grid-column: date;
  line-height: 24px;
  margin-right: 10px;
  text-transform: uppercase;
  vertical-align: top;
  white-space: nowrap;
  min-width: 55px;
}
.neo-contentlist__item a .neo-contentlist__item__eventdate--day {
  display: inline;
  font-weight: var(--weight-bold-condensed);
}
.neo-contentlist__item a .neo-contentlist__item__eventdate--month {
  display: inline;
}
.neo-contentlist__item a .neo-contentlist__item__eventdate--month::before {
  content: " ";
}

.neo-contentlist__item__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 17px;
  font-weight: var(--weight-light-condensed);
  grid-column: title;
  line-height: 24px;
}

.neo-contentlist__item__description {
  padding-top: 10px;
}

.neo-contentlist__item__tags {
  color: var(--color-grey-normal);
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  grid-column: 1/span 2;
  line-height: 23px;
  margin-left: -3px;
  margin-top: 20px;
}
.neo-contentlist__item__tags > .neo-contentlist__item__tag {
  background-color: var(--color-grey-light);
  border-radius: 3px;
  display: inline-block;
  flex: none;
  margin: 0 0 3px 3px;
  order: 2;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
}
.neo-contentlist__item__tags > .neo-contentlist__item__tag:hover {
  background-color: var(--color-grey-normal);
  color: var(--color-grey-light);
}
.neo-contentlist__item__tags > .neo-contentlist__item__tag--hasrole {
  background-color: #FDF6EE;
  color: var(--color-tahiti-gold);
  order: 1;
}
.neo-contentlist__item__tags > .neo-contentlist__item__tag--hasrole:hover {
  background-color: #FF9F3F;
  color: #FDF6EE;
}

.neo-contentlist__loading {
  opacity: 0;
  transition: opacity 0.5s;
}
.neo-contentlist__loading--nowloading {
  opacity: 1;
}

.neo-contentlist__loading__text {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.neo-contentlist__empty {
  margin: 30px 0 40px;
}