.wh-rtd__editor {
  width: 100%;
  min-height: 200px;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
}
.wh-rtd__editor:focus-within {
  border-color: grey;
  box-shadow: 0 none;
}
.wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-rtd__editor .wh-rtd-toolbar {
  border-radius: 2px 2px 0 0;
}
.wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}