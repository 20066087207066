/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
.widget-section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 30px;
}
.widget-section:first-child {
  padding-top: 0;
}
.widget-section:not(.widget-section--hidden) > .widget-section__content {
  position: relative;
  padding: 35px;
}
@media (max-width: 830px) {
  .widget-section:not(.widget-section--hidden) > .widget-section__content {
    padding: 20px;
  }
}
.widget-section .widget-section__content > *:last-child,
.widget-section .widget-section__widgets > *:last-child {
  margin-bottom: 0;
}
p + .widget-section {
  padding-top: 25px;
}
h2 + .widget-section {
  padding-top: 20px;
}
.widget-section + p {
  margin-top: -30px;
}
.widget-section + h2 {
  margin-top: -10px;
}
.widget-section--hidden.widget-section {
  padding-top: 0;
  display: none;
}
.widget-section .widget-section__widgets {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0 !important;
}
.widget-section .widget-section__widgets > .widget {
  flex: 1 1 50%;
  margin-left: 35px;
  margin-top: 35px;
  max-width: calc(50% - 35px);
}
@media (max-width: 750px) {
  .widget-section .widget-section__widgets {
    display: block;
  }
  .widget-section .widget-section__widgets > .widget {
    margin-left: 20px;
    max-width: none;
  }
  .widget-section .widget-section__widgets .widget-person--contactperson {
    padding-left: 0;
    padding-right: 0;
  }
}
.widget-section--nonpersonalized {
  display: none;
  padding-top: 30px;
  padding-bottom: 0;
}
.widget-section--nonpersonalized + p {
  margin-top: 30px;
}
.widget-section--nonpersonalized.widget-section--show {
  display: block;
}
.widget-section--nonpersonalized.widget-section--show + p {
  margin-top: -30px;
}
.widget-section--nonpersonalized.widget-section--show + h2 {
  margin-top: -10px;
}
.widget-section .universe {
  z-index: -1;
}
.widget-section--loggedin .login-msgbox {
  display: none;
}
.widget-section .widget-section__background ~ .widget-section__content .login-msgbox__content {
  background-color: transparent;
}
.widget-section .widget-section__background ~ .widget-section__content .login-msgbox__content .close {
  color: var(--color-black);
  display: none;
}

.widget-section__background {
  opacity: 0.07;
  position: absolute;
  background-color: var(--color-tahiti-gold);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.widget-section__personalized {
  border-radius: 5px;
  border: 1px solid var(--color-tahiti-gold);
  color: var(--color-tahiti-gold);
  display: none;
  float: right;
  font-size: 16px;
  font-weight: 300;
  margin-left: 20px;
  margin-top: -8px;
  padding: 10px;
}
.widget-section__personalized .icon {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.widget-section--loggedin .widget-section__personalized {
  display: inline-block;
}

.widget-section__floathelper {
  height: 30px;
}

.widget-section__buttonholder {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.widget-section__buttonholder--hide {
  display: none;
}

.widget-section__button.wh-form__button {
  background-color: var(--color-tahiti-gold);
  flex: none;
}
.widget-section__button.wh-form__button--disabled {
  background-color: var(--color-grey-dark);
  cursor: default;
  opacity: 0.2;
}
.widget-section__button.wh-form__button--disabled:hover {
  opacity: 0.2;
}

.widget-section__close {
  border: 2px solid var(--color-grey-normal);
  border-radius: 100%;
  cursor: pointer;
  float: right;
  height: 64px;
  margin-top: -35px;
  position: relative;
  width: 64px;
}
.widget-section__close::before, .widget-section__close::after {
  background: var(--color-grey-normal);
  content: "";
  height: 2px;
  position: absolute;
  left: 18px;
  top: 30px;
  width: 25px;
}
.widget-section__close::before {
  transform: rotate(45deg);
}
.widget-section__close::after {
  transform: rotate(-45deg);
}